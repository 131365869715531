.circle-container {
  display: flex;
  gap: 45px;
  margin-top: 19px;
}

.circle {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
  font-weight: bold;
  font-size: 1.2rem;
  border: 1px solid black !important;
}

.inner-circle {
  z-index: 2;
}

.name {
  position: absolute;
  bottom: -45px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  text-align: center;
}

@media (max-width: 790px) {
  .circle {
    width: 32px;
    height: 32px;
  }
  .name {
    font-size: 0.7rem;
  }
  .circle-container {
    display: flex;
    gap: 36px;
    margin-top: 5px;
    justify-content: center;
  }
}
