.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 15px;
  color: black;
}

.slider_btn {
  /* background-image: linear-gradient(133deg, #ff6144, #ef458d); */
  border-radius: 50%;
  height: 35px;
  width: 35px;
  position: relative;
  cursor: pointer;
  transition: all 0.05s ease-in;
}

.slider_btn:hover {
  transform: scale(1.1);
}

.slider_icon {
  height: 70%;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke-width: 2.5px;
}

.pagination_wrapper {
  /* background-color:#fc8a4c; */
  height: 25px;
  width: 25px;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.05s ease-in;
  flex-shrink: 0;
}
.pagination_wrapper:hover,
.pagination_wrapper_active {
  background-color: black;
  /* background-image: linear-gradient(133deg, #ff6144, #ef458d); */
  color: white;
  transform: scale(1.15);
}
.pagination_number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
}

.moreIcon{
  height: 20px;
  width: 20px;
}

.moreIcon img {
  width: 100%;
  height: 100%;
}

.middleNumbersWrap {
  width: 188px;
  overflow: hidden;
}

.middleNumbers {
  display: flex;
  gap: 15px;
  height: 30px;
  padding: 0 2px;
  justify-content: flex-start;
  align-items: center;
  
  transition: all 0.2s ease-in-out;
}

@media (max-width: 768px) {
  .wrapper {
    gap: 0px;
  }
}
