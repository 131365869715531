.card-wrapper {
  display: flex;
  gap: 25px;
  /* align-items: center; */
  margin-bottom: 50px;
}

.features-img {
  width: 55%;
  padding: 30px;
  align-self: center;
}

.features-img img {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.23);
}

.text-section-wrap {
  width: 45%;
  padding: 30px;
}

.meet-title {
  font-size: 30px;
  line-height: 1.18;
  font-weight: 600;
  font-style: normal;
}

.text-list {
  display: flex;
  align-items: first baseline;
  margin: 0 0 30px 0;
}

.list-bullet {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #68f1bd;
}

.text-list p {
  margin: 0 0 0 15px;
  width: 95%;
  color: #fff;
}

.meet-list {
  font-size: 20px;
  color: #000;
}

.text-section-about {
  width: 50%;
  /* padding: 30px; */
}

.text-section-about h2 {
  font-size: 1.8rem;
  padding-bottom: 0.8em;
  border-bottom: 1px solid #aaaaaa;
  margin: 0 0 1em;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
}
.text-section-about h3 {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5em;
  margin: 0 0 1em;
  color: #fff;
}

.text-section-about p {
  /* margin-bottom: 2em; */
  line-height: 1.7em;
  font-size: 18px;
}

.main-meet-btn {
  display: flex;
  margin: 35px 10px;
  align-items: center;
  color: #fff;
}

.meeting-play-btn {
  border: none;
  background: none;
  color: #fff;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  align-items: center;
  margin-top: 12px;
  margin-right: 16px;
  padding-bottom: 14px;
  font-family: Space Grotesk, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
  transition: border-color 0.3s, color 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 0.3s;
  display: flex;
  gap: 10px;
}

.meeting-reg-btn {
  font-size: 1rem;
  position: relative;
  z-index: 0;
  display: flex;
  width: auto;
  background-size: 200% auto;
  color: #fff;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  background-color: #303fe1;
  border: 2px solid #303fe1;
  border-radius: 4px;
  margin-right: 16px;
  padding: 10px 30px;
  font-family: Space Grotesk, sans-serif;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
  transition: border-color 0.3s, color 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 0.3s;
}

.meeting-reg-btn:hover {
  background-color: #fff;
  color: #303fe1;
  border: 2px solid #fff;
  outline: none;
}

.about-img {
  box-shadow: none !important;
}

@media (max-width: 1270px) {
  .text-list {
    gap: 14px;
  }
  .text-section-wrap {
    padding: 0px;
  }
}

@media (max-width: 1250px) {
  .meet-title {
    font-size: 42px;
  }
  .text-list p {
    margin: 0 0 0px 0;
  }
}

@media (max-width: 1100px) {
  .card-wrapper {
    flex-direction: column;
  }
  .features-img {
    width: 100%;
    padding: 0;
  }
  .text-section-wrap {
    width: 100%;
  }
  .text-section-about {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .meet-title {
    font-size: 36px;
  }
}

@media (max-width: 550px) {
  .text-section-about h2 {
    font-size: 1.6rem;
  }
  .meet-title {
    font-size: 28px;
  }
  .meet-list {
    font-size: 18px;
  }

  .meeting-play-btn {
    font-size: 13px;
  }

  .main-meet-btn {
    margin: 0 0;
  }

  .meeting-reg-btn {
    font-size: 0.8rem;
    line-height: 16px;

    margin-right: 11px;
  }
}

@media (max-width: 380px) {
  .text-section-about h2 {
    font-size: 1.3rem;
  }
  .meeting-play-btn {
    font-size: 8px;
  }

  .main-meet-btn {
    margin: 0 0;
  }

  .meeting-reg-btn {
    font-size: 0.6rem;
    line-height: 11px;
    margin-right: 6px;
  }
}
