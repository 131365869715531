.wrapper {
  background: #000000ad;
  height: 91%;
  width: 95%;
  position: absolute;
  top: -5px;
  left: -8px;
  z-index: 2;
  margin: 0 5%;
  padding: 20px;
  display: flex;
  align-items: center;
  border-radius: 0.5ch;
}

.meta_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 10px;
}

.submit_btn {
  padding: 5px 5px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #ff512f, #dd2476);
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  color: #f5f5f5;
  transition: all 0.2s ease-in;
  border: none;
}

.place_meta {
  padding: 5px 12px !important;
  border-radius: 5px;
  width: 200px;
}

.place_meta::placeholder {
  font-size: 0.9375rem;
}

.loading_wrapper {
  background-color: black;
  height: 100%;
  height: 91%;
  width: 94%;
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 2;
  margin: 0 5%;
  padding: 20px;
  border-radius: 0.5ch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input::placeholder {
  font-size: 1rem;
}

.loading-div {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border-top: 3px solid white;
  animation: mymove 0.5s linear infinite;
}

@keyframes mymove {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .place_meta {
    padding: 5px 9px !important;
    width: 186px !important;
  }
}

@media (max-width: 768px) {
  .place_meta {
    padding: 5px 11px !important;
    width: 170px !important;
  }

  .wrapper {
    height: 100%;
    left: -7px;
  }
  .place_meta::placeholder {
    font-size: 0.75rem;
  }
}

@media (max-width: 573px) {
  .place_meta {
    padding: 5px 5px !important;
  }
  .wrapper {
    height: 100%;
    width: 92%;
    left: -3px;
  }
  .place_meta::placeholder {
    font-size: 0.8rem;
    padding: 5px;
  }
}

@media (max-width: 450px) {
  .submit_btn {
    padding: 2px 3px;
    left: 20%;
    font-size: 12px;
  }
  .wrapper {
    height: 100%;
    width: 92%;
    left: -3px;
  }
  .place_meta {
    padding: 2px 0px !important;
    width: 115px !important;
  }
  .meta_button {
    top: 65%;
  }
  .place_meta::placeholder {
    font-size: 0.54rem;
    padding: 5px;
  }
}
@media (max-width: 350px) {
  .submit_btn {
    padding: 2px 6px;
    left: 20%;
  }
  .place_meta {
    padding: 2px 0px !important;
    width: 99px !important ;
  }
  .meta_button {
    top: 60%;
  }
}
