.container-home {
  position: relative;
  width: 100%;
  height: 100vh;
}

.main-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.background_img {
  position: relative;
  width: 100%;
  height: 100vh;
}

.background-video {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mainbutton_main {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  border: 1px solid #fff;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  display: flex;
  display: table-footer-group;
  font-weight: 600;
  font-size: 1rem;
  padding: 10px 50px 10px 25px;
  width: auto;
  z-index: 10;
  transition: 0.3s ease-in-out;
  background-size: 200% auto;
  border: navajowhite;
}
.mainbutton_main:hover {
  border: 1px solid white;
}

.main--down-btn1 {
  color: #fff;
  letter-spacing: 1px;
  font-weight: 500;
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  padding: 15px 16px;
  font-size: 1rem;
  margin-left: 10px;
  border-radius: 5px;
  position: relative;
  z-index: 0;
  display: flex;
  width: auto;
  margin: auto;
  padding: 6px 15px;
  border: 1px solid #fff;
  border-radius: 100px;
  transition: 0.3s ease-in-out;
  font-size: 0.9375rem;
  cursor: pointer;
  transition: 0.5s;
  background-size: 200% auto;
  border: navajowhite;
}

.main--down-btn1:hover {
  position: relative;
  z-index: 10;
  display: flex;
  border: 1px solid #d4c7c7;
  transition: 0.3s ease-in-out;
  font-size: 0.9375rem;
  cursor: pointer;
  color: #fff;
}

.video-title {
  position: absolute;
  bottom: 48%;
  left: 50%;
  transform: translateX(-50%);
  /* color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); */
  text-align: center;
  width: 100%;
  color: #fff;
  font-size: 3.125rem;
  font-weight: 900;
  line-height: 111%;
  text-wrap: balance;
  
}

.home_name {
  color: #fff;
  font-size: 2.7rem;
  font-weight: 600;
  line-height: 111%;

  z-index: 0;
}

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 40%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  text-align: center;
}

/*  */

@media (max-width: 1400px) {
  .home_name {
    font-size: 2.425rem;
  }
}

@media (max-width: 768px) {
  .home_name {
    font-size: 2.125rem;
  }
}

@media (max-width: 500px) {
  .mainbutton_main {
    font-size: 1.025rem;
    margin: -50px;
    padding: 10px 50px 10px 25px;
  }
}

@media (max-width: 400px) {
  .button_avtar {
    padding: 11px 25px;
    font-size: 11px;
  }
  .home_name {
    font-size: 1.2rem !important;
  }
}

@media (max-height: 400px) {
  .home_name {
    font-size: 1.925rem;
  }

  .video-title {
    bottom: 38%;
  }
  .content {
    top: 75%;
  }
}


