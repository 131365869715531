ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  background: #151414;
  position: relative;
  
}
.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta {
  display: flex;
  align-items: center;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin: 0 0 15px 0px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin: 20px 0;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #dd4b39;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  left: 0;
  position: absolute;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #ff5e14;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a {
  color: #ff5e14;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #ff5e14;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.app-icon {
  display: flex;
  gap: 10px;
}

.app-img { 
  height: 40px;
}

.s-img {
  height: 25px;
  margin: 5px 5px;
}

.p-img {
  height: 85px;
}

.country_name {
  display: flex;
  gap: 20px;
}

.footer-widget-logo {
  color: #757575;
}

.footer-widget-logo h4 {
  color: #757575;
  font-size: 18px;
  margin-top: 10px;
  text-align: center;
}

/* email */

.search {
  display: inline-block;
  position: relative;
}

.search input[type="text"] {
  width: 320px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  outline: none;
}

.search button[type="submit"] {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  ) !important;
  border: 1px solid #fff;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  font-size: 1rem;
  padding: 10px 20px 10px 20px;
  width: auto;
  z-index: 10;
  transition: 0.3s ease-in-out;
  background-size: 200% auto;
  border: navajowhite;
}

/* .search button[type="submit"]:hover {
  background: none;
  background-color: ;
} */

.cta-text a {
  color: #7e7e7e;
}

.cta-text a:hover {
  color: rgb(71, 71, 229);
}

.logo-img {
  height: 40px;
}

.Logo_footer {
  height: 60px;
  background-color: #fff;
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  gap: 90px;
}

.footer-social-icon {
  display: flex;
  gap: 20px;
}

@media (max-width: 1200px) {
  .search input[type="text"] {
    width: 290px;
  }
}

@media (max-width: 768px) {
  .Logo_footer {
    background-color: none;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    justify-content: flex-start;
    /* align-items: start; */
    padding: 10px;

    height: auto;
  }

  .footer-widget-2 {
    margin-top: 24px;
  }

  .cta-text {
    padding-left: 15px;
    display: inline-block;
    margin: 4px;
  }
}

@media (max-width: 420px) {
  .Logo_footer {
    background-color: none;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
    justify-content: flex-start;
    /* align-items: start; */
    padding: 10px;

    height: auto;
  }

  .logo-img {
    height: 28px;
  }

  .search input[type="text"] {
    width: 278px;
  }
}
