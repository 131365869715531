  .main_about_wrapper {
  width: 100%;
  background: #e5e4e4;
  height: 100%;
}

.sub_about_wrapper {
  background-image: url(../../assets/images/Bg.jpg);
  height: 450px;
  position: relative;
  display: flex;
}

.section_user_journey {
  width: 40%;
  position: absolute;
  top: 20%;
  left: 5%;
  color: white;
}

.pragrap_user_Journey {
  line-height: 1.6;
}
.section_user_journey h1 {
  font-weight: 800;
  font-size: 45px;
}

.About-create-button {
  background-image: linear-gradient(to right, #ff512f, #dd2476);
  color: white;
  padding: 10px 54px;
  border-radius: 100px;
  font-weight: 600;
  font-size: 1rem;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in;
  backface-visibility: none;
  border: none;
}

.About-create-button:hover {
  transform: scale(1.02);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.power_sector {
  background-color: #e5e4e4;
}

.mid-content3 {
  padding: 40px 267px;
  font-size: 1.125rem;
  text-align: justify;

  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  color: #e9ecef;
  background-position-y: 30%;
  background: radial-gradient(
      100% 20% at 5% -2%,
      rgba(85, 25, 255, 0.25) 0,
      rgba(85, 25, 255, 0) 100%
    ),
    radial-gradient(
      50% 50% at -20% 100%,
      rgba(154, 207, 255, 0.2) 0,
      rgba(154, 207, 255, 0) 100%
    ),
    radial-gradient(
      50% 50% at 95% 100%,
      rgba(171, 0, 255, 0.2) 0,
      rgba(187, 49, 255, 0) 100%
    ),
    linear-gradient(180deg, #be5bef 14.58%, #ffe6ff 78.96%);
}

.sec-section h3 {
  font-size: 2.5rem;
  font-weight: 800;
  color: black;
  padding: 50px 0px;
  text-align: center;
}

.sector-sec img {
  width: 180px;
  border-radius: 50%;
  box-shadow: 0 0px 6px rgb(13 2 38 / 43%), 0 6px 6px rgb(0 0 0 / 23%);
  margin-bottom: 25px;
}

.sector-sec {
  text-align: center;
  display: flex;
  padding: 0px 0 80px 0;
}

.content-sec {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}

.content-sec .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 100%;
  left: 20%;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
  width: 185px;
  border-radius: 50%;
  box-shadow: 0 0px 6px rgb(13 2 38 / 43%), 0 6px 6px rgb(0 0 0 / 23%);
  margin-bottom: 25px;
  justify-content: center;
  text-align: center;
  display: flex;
}

.content-sec:hover .content-overlay {
  opacity: 1;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content-details h3 {
  color: #fff;
  font-weight: 500;
  margin-bottom: 0.5em;
  font-size: 1.25rem;
}

.content-details p {
  color: #fff;
  font-size: 0.8em;
}

.content-sec:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.fadeIn-bottom {
  top: 80%;
}

.slider-button {
  outline: none;
  border: none;
  background: none;
}

.hr-tag {
  margin-top: 50px;
}

.main-video-section {
  display: flex;
  justify-content: space-around;
  background-color: white;
  padding: 80px 0 80px 0;
}

.flow-video {
  height: 300px;
}

.Create-meta-about h3 {
  font-size: 2.5rem;
  font-weight: 800;
  color: black;
}

.Create-meta-about ul {
  margin-top: 20px;
  margin-left: 22px;
}

.Create-meta-about ul li {
  font-size: 20px;
  letter-spacing: 0.8px;
  font-weight: 500;
  margin-top: 15px;
}

.Create-meta-about button {
  margin-top: 20px;
  margin-left: 10px;
}

.mid-content2 {
  padding: 15px 170px;
  font-size: 1.125rem;
  text-align: center;
  background: white;
}

.business_heading {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 900;
  color: #000;
  padding: 30px 0px;
}

o-logo {
  padding: 0px 30px;
}

.i-logo {
  padding: 5px 10px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.i-logo img {
  filter: gray; /* IE5+ */
  -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
  -webkit-transition: all 0.8s ease-in-out;
  width: 60%;
}

.logo-note {
  font-size: 0.625rem;
  float: right;
}

.Join_world_beyond_section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #e5e4e4;
  padding: 80px 0 80px 0;
}

.join-section h3 {
  font-size: 2.5rem;
  font-weight: 800;
  color: black;
}

.s-video {
  height: 300px;
  width: 700px;

  background-color: rgb(0 0 0);
  border-radius: 20px;
}

.join-btn {
  display: flex;
  margin-top: 20px;
}

.login-btn {
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: 500;
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  padding: 10px 40px;
  border-radius: 5px;
  position: relative;
  z-index: 0;
  display: flex;
  width: auto;
  border: 1px solid #fff;
  border-radius: 100px;
  transition: 0.3s ease-in-out;
  font-size: 0.9375rem;
  cursor: pointer;
  transition: 0.5s;
  background-size: 200% auto;
  border: 1px solid transparent;
}

.login-btn:hover {
  position: relative;
  z-index: 10;
  display: flex;
  border: 1px solid #d4c7c7;
  transition: 0.3s ease-in-out;
  font-size: 0.9375rem;
  cursor: pointer;
  color: #fff;
}

.login-btn1 {
  color: black;
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: 500;
  background: none;
  padding: 10px 40px;
  border-radius: 5px;
  margin-left: 10px;
  position: relative;
  z-index: 0;
  display: flex;
  width: auto;
  border: 1px solid black;
  border-radius: 100px;
  transition: 0.3s ease-in-out;
  font-size: 0.9375rem;
  cursor: pointer;
  transition: 0.5s;
  background-size: 200% auto;
}

.login-btn1:hover {
  position: relative;
  z-index: 10;
  display: flex;
  border: 1px solid #d4c7c7;
  transition: 0.3s ease-in-out;
  font-size: 0.9375rem;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  color: #fff;
}

/* start media query */

@media (max-width: 1530px) {
  .section_user_journey {
    top: 5%;
  }
}

@media (max-width: 1440px) {
  .content-sec .content-overlay {
    left: 16%;
  }
  .section_user_journey {
    width: auto;
  }
  .sub_about_wrapper {
    height: 300px;
  }
  .Create-meta-about h3 {
    font-size: 1.6rem;
  }
  .flow-video {
    height: 270px;
  }
}

@media (max-width: 1320px) {
  .content-sec .content-overlay {
    left: 9%;
  }
  .s-video {
    height: 300px;
    width: 520px;

    background: none;
    border-radius: 20px;
  }
  .join-section h3 {
    font-size: 2.2rem;
  }
  .content-sec .content-overlay {
    left: 14%;
  }
}
@media (max-width: 1150px) {
  .content-sec .content-overlay {
    left: 9%;
  }
}

@media (max-width: 1020px) {
  .content-sec .content-overlay {
    left: 0%;
  }
  .content-sec {
    width: auto;
  }

  .flow-video {
    height: 240px;
  }
  .main-video-section {
    gap: 27px;
  }
  .Create-meta-about ul {
    margin-top: auto;
  }
  .Create-meta-about ul li {
    font-size: 15px;
  }
  .Create-meta-about button {
    margin-top: 11px;
  }

  .s-video {
    height: 183px;
    width: 395px;
  }
  .join-section h3 {
    font-size: 1.8rem;
  }
  .login-btn {
    padding: 10px 11px;
    font-size: 11px !important;
  }
  .login-btn1 {
    padding: 10px 11px;
    font-size: 11px !important;
  }
}

@media (max-width: 820px) {
  .Create-meta-about ul li {
    font-size: 15px;
    margin-top: 4px;
  }
}

@media (max-width: 767px) {
  .flow-video {
    height: 180px;
  }
  .Create-meta-about h3 {
    font-size: 1.2rem;
  }
  .sub_about_wrapper {
    height: 350px;
  }
}

@media (max-width: 680px) {
  .content-sec .content-overlay {
    width: 152px;
  }
  .sector-sec img {
    width: 150px;
  }

  .sub_about_wrapper {
    height: 400px;
  }
  .section_user_journey h1 {
    font-size: 31px;
  }
  .main-video-section {
    flex-direction: column-reverse;
  }

  .Create-meta-about ul li {
    font-size: 20px;
    margin-top: 16px;
  }
  .Create-meta-about h3 {
    font-size: 2rem;
  }
  .flow-video {
    height: 310px;
    display: flex;

    margin: auto;
  }
  .Create-meta-about {
    display: block;
    margin: auto;
  }

  .Join_world_beyond_section {
    padding: 20px 130px 20px 0;
    flex-direction: column;
    gap: 50px;
    /* align-items: flex-start; */
    /* margin: 38px; */
  }
  .s-video {
    height: 277px;
    width: 395px;
  }
  .main-video-section {
    padding: 26px 0 40px 0;
  }
  .mid-content2 {
    padding: 15px 15px;
  }
  .business_heading {
    text-align: center;
    font-size: 2.8rem;
  }
}

@media (max-width: 600px) {
  .sector-sec img {
    width: 130px;
  }
  .flow-video {
    height: 235px;
    display: flex;

    margin: auto;
  }
  .Create-meta-about h3 {
    font-size: 1.5rem;
  }
  .join-section h3 {
    font-size: 1.5rem;
  }
  .s-video {
    height: 241px;
    width: 395px;
  }
  .Join_world_beyond_section {
    padding: 20px 130px 20px 0;
    flex-direction: column;
    gap: 50px;
    /* align-items: flex-start; */
    /* margin: 38px; */
  }
  .Create-meta-about {
    display: block;
    margin: 0 0 0 22px;
  }
  .business_heading {
    text-align: center;
    font-size: 1.5rem;
  }
  .sec-section h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 550px) {
  .content-sec .content-overlay {
    width: 110px;
  }
  .sector-sec img {
    width: 110px;
  }
  .Join_world_beyond_section {
    padding: 20px 110px 20px 0;
    flex-direction: column;
    gap: 50px;
  }
  .mobile_video {
    margin: auto;
  }
}
@media (max-width: 450px) {
  .content-sec .content-overlay {
    width: 99px;
  }
  .sector-sec img {
    width: 100px;
  }
  .sub_about_wrapper {
    height: 510px;
  }
  .flow-video {
    height: 220px;
    display: flex;

    margin: auto;
  }
  .Create-meta-about h3 {
    font-size: 1.5rem;
  }
  .Create-meta-about ul li {
    font-size: 16px;
    margin-top: 12px;
  }
  .Join_world_beyond_section {
    padding: 10px 0;
  }
  .join-section h3 {
    font-size: x-large;
  }
}

@media (max-width: 450px) {
  .sub_about_wrapper {
    height: 510px;
  }
  .flow-video {
    height: 202px;
  }
  .Create-meta-about h3 {
    font-size: 1.4rem;
  }
  .Create-meta-about ul li {
    font-size: 15px;
    margin-top: 12px;
  }
}

@media (max-width: 420px) {
  .sector-sec img {
    width: 95px;
  }
  .sub_about_wrapper {
    height: 564px;
  }
  .flow-video {
    height: 180px;
  }
  .s-video {
    height: 193px;
    width: 395px;
  }
  .mobile_video {
    margin: 0;
  }
}
