.button {
  display: none;
}

.sidebar {
  background-color: #3f434642;
  position: absolute;
  top: 9%;
  left: 0;
  width: 375px;
  text-align: center;
  z-index: 1000000;
  transition: all 0.2s ease-out;
}

.sidebarVisible {
  left: 0;
}

.labelpt {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid red;
  background-color: transparent;

  position: absolute;
  top: 50%;
  left: 50%;

  border-radius: 50%;
}

.radioButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10 !important;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.quiz-window {
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  margin: auto;
  width: 375px;
  border-radius: 4px;
  /* background: #fff; */
  overflow: hidden;
}
.quiz-window-header {
  padding: 20px 15px;
  text-align: center;
  position: relative;
}
.quiz-window-title {
  font-size: 1.625rem;
}
.quiz-window-close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  background: none;
  border: 0;
  width: 30px;
  height: 30px;
  font-size: 3.75rem;
  font-weight: 100;
  line-height: 24px;
  color: #777;
  cursor: pointer;
}
.quiz-window-body {
  /* background-color: #f9f9f9; */
}
.guiz-awards-row {
  margin: 0;

  list-style: none;
}
.guiz-awards-row:after {
  content: "";
  display: table;
  clear: both;
}
.guiz-awards-row-even {
  background-color: #2e4760;
  color: #fff;
  display: flex;
  /* border-bottom: 1px solid #a19b9b; */
  box-shadow: 0 0px 7px #fff, 16px 12px 7px #fff;
  /* border-radius: 5px; */
  margin-top: 2px;
}
.guiz-awards-row li {
  display: inline-block;
  vertical-align: top;
  float: left;
}
.guiz-awards-header {
  text-align: center;
  padding: 13px 40px;
  background: #041252;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
}
.guiz-awards-star,
.guiz-awards-track,
.guiz-awards-time,
.guiz-awards-header-star,
.guiz-awards-header-track,
.guiz-awards-header-time {
  min-width: 54px;
  text-align: center;
  width: 16%;
}
.guiz-awards-title {
  width: 40%;
  min-width: 186px;
  font-size: 1.125rem;
  font-weight: normal;
  padding-top: 3px;
  text-align: center;
}
.guiz-awards-title2 {
  width: 40%;
  min-width: 160px;
  font-size: 1.125rem;
  font-weight: normal;
  padding-top: 3px;
  text-align: left;
}
.guiz-awards-header-title {
  width: 40%;
  min-width: 160px;
}

.face-img {
  border-radius: 50%;
  width: 40px;
}
.guiz-awards-subtitle {
  color: #858585;
  font-size: 0.875rem;
  font-weight: 300;
}
.guiz-awards-track,
.guiz-awards-time {
  width: 22%;
  min-width: 80px;
  font-size: 1.125rem;
  line-height: 45px;
}
.guiz-awards-header-track,
.guiz-awards-header-time {
  width: 22%;
  min-width: 80px;
}
.guiz-awards-track .null,
.guiz-awards-time .null {
  color: #bababa;
}
.star {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #bdc2c1;
  background: #d6d6d6;
}
.goldstar {
  border-color: #4c8193;
  background: #14b0bf;
}
.silverstar {
  border-color: #557e3a;
  background: #66931f;
}
.bronzestar {
  border-color: #998247;
  background: #aa984b;
}
.rhodiumstar {
  border-color: #743a7f;
  background: #a0409d;
}
.platinumstar {
  border-color: #10393b;
  background: #2b5770;
}

.guiz-awards-buttons {
  background: #fff;
  text-align: center;
  padding: 20px 0;
}
.guiz-awards-but-back {
  display: inline-block;
  background: none;
  border: 1px solid #61a3e5;
  border-radius: 21px;
  padding: 7px 40px 7px 20px;
  color: #61a3e5;
  font-size: 1.0625rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.guiz-awards-but-back:hover {
  background: #61a3e5;
  color: #fff;
}

.guiz-awards-but-back i {
  font-size: 1.625rem;
  line-height: 17px;
  margin-right: 20px;
  position: relative;
  top: 2px;
}

.my_chat_message {
  align-self: end;
  color: black;
  font-size: 0.875rem;
  width: 50%;
  text-align: right !important;
}

.from_chat_message {
  align-self: start;
  color: black;
  font-size: 0.875rem;
  width: 50%;
  text-align: left;
}

.from_chat_message p,
.my_chat_message p {
  margin: 0;
}

.messages {
  display: flex;
  flex-direction: column;
  padding: 23px;
  background: white;
  width: 95%;
  margin: auto;
  border-radius: 3px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  height: 55vh;
  overflow-y: scroll;
}
.chatinput {
  height: 40px;
  width: 81%;
  padding: 10px;
  vertical-align: middle;
  background: white;
  color: black;
  font-size: 0.875rem;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.chatinput:focus-visible {
  outline: none;
}
.back-btn {
  background: #021634;
  padding: 10px;
  margin: 5px;
  color: #fff;
  font-weight: 800;
  border-radius: 5px;
  cursor: pointer;
}
.c-i-b {
  display: flex;
  margin: 10px;
}
.c-btn {
  padding: 0px 20px;
  height: 40px;
  font-size: 15px;
  background-image: linear-gradient(to right, #ff512f, #dd2476);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-weight: 500;
}
.chat-icon {
  cursor: pointer;
  color: #f1a906;
}

.chathead {
  background: white;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 95%;
  margin: 10px;
  border-radius: 3px;
}

.chathead h1 {
  color: black;
  font-size: 1.25rem;
}
.chat-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
  box-shadow: 0 2px 2px black !important;
  transition: all 0.05s ease-in;
  border: none;
}
.chat-close-button:hover svg {
  stroke: red;
}

.chat-close-button svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 80%;
  width: 80%;
  stroke: #111;
  stroke-width: 2.5px;
  transition: all 0.05s ease-in;
  border: none;
}

.NoliveVisiter {
  color: red;
  font-size: 1.125rem;
  font-weight: 800;
}

@media (max-width: 500px) {
  .sidebar {
    width: 318px;
    top: 15%;
  }

  .quiz-window {
    width: 318px;
  }

  .guiz-awards-title {
    min-width: 155px;
    font-size: 14x;
  }
  .guiz-awards-header {
    padding: 13px 8px;

    font-size: 0.8125rem;
  }
}

/* @media (max-width: 380px) {

    .sidebar {
      width: 318px;
      top: 35%;
    }
} */
