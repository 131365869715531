.wrapper {
    max-width: 960px;
    width: 100%; 
    margin: 30px auto;
    transform: scale(0.8);
  }
  .landing-page {
    max-width: 960px;
    height: 475px;
    margin: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #fafafa;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
 
  .error-title {
    font-size: 48px;
    margin: 0;
  }
  .error-msg{
    font-size: 16px;
    width: 35%; 
    margin: 16px auto 24px;
    text-align: center;
  }
  .error-btn {
    background-image: linear-gradient(
        to right,
        #ff512f 0%,
        #dd2476 51%,
        #ff512f 100%
      );
      border: 1px solid #fff;
      border-radius: 100px;
      color: #fff;
      cursor: pointer;
      display: flex;
      display: table-footer-group;
      font-weight: 600;
      font-size: 1rem;
      margin: 10px;
      padding: 8px 24px;
      width: auto;
      z-index: 10;
      transition: 0.3s ease-in-out;
      background-size: 200% auto;
      border: navajowhite;
  }
  