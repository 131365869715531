.main_1 {
  height: 100vh;
  width: 100%;
  background: #000000f2;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  overflow: hidden;
}

.registration-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #ffffff;
  width: 417px;
  border-radius: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.registration-form button {
  align-self: center;

  width: 95%;
}

.flex-column > label {
  color: #151717;
  font-weight: 600;
  border-top-left-radius: 15px;
}

.inputForm {
  border: 1.5px solid #ecedec;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
  margin: 10px;
}

.input {
  margin-left: 10px;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 100%;
}

.input:focus {
  outline: none;
}

.inputForm:focus-within {
  border: 1.5px solid #2d79f3;
}

.span {
  font-size: 14px;
  margin-left: 5px;
  color: #2d79f3;
  font-weight: 500;
  cursor: pointer;
}

.button-submit {
  position: relative;
  width: 100%;

  height: 40px;
  color: white;
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;

  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );

  transition: 0.3s ease-in-out;
  background-size: 200% auto;
  border: navajowhite;
}

.reg-main-section {
  position: relative;
  width: 100%;
  margin: auto;
}

.reg-main-section img {
  width: 100%;
  height: 175px;
  padding: 0;
  margin: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.sub-section {
  position: absolute;
  left: 18%;
  top: 60%;
  width: 250px;
  text-align: center;
}

.sub-section img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #000;
}

.svg_wrap {
  height: 25px;
  width: 25px;
  background-color: aliceblue;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-9%, 12%);
  cursor: pointer;
  background-image: linear-gradient(90deg, #f65f65, #ff50e8);
  color: #fff;
}

.svg_wrap svg {
  stroke: #fff;
  transform: rotate(45deg);
  height: 25px;
  width: 25px;
}

.loading_wrapper {
  height: 100%;
  width: 100%;
  background-color: black;
  position: absolute;
  top: 0;
  z-index: 10;
}

.loading-div {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border-top: 3px solid white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: mymove 0.5s linear infinite;
}

@keyframes mymove {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.edit_Profile_Info {
  color: black !important;
  font-size: 20px;
}

.profileEdit {
  position: absolute;
  left: 55%;
  top: -4px;
}

.Info {
  cursor: pointer;
  color: #f65f65;
}

.sub-section h4 {
  font-size: 21px;
  font-weight: 700;
  color: black;
  margin-top: 5px;
}

.Already-registaer {
  margin-bottom: 14px;
  text-align: center;
  color: black;
  font-size: 14px;
}

.registration_wrapper {
  margin-top: 62px;
}

.showPassword {
  position: absolute;
  right: 16px;
  bottom: 119px;
}

@media (max-width: 450px) {
  .registration-form {
    width: 301px;
  }
  
.reg-main-section img {
  height: 110px;
}

.sub-section {
  top: 30%;
  width: 190px;
}

.sub-section img {
  height: 90px !important;
  width: 90px;
}

.inputForm {

  height: 40px;

  margin: 10px;
}

.registration_wrapper {
  margin-top: 44px;
}

}

