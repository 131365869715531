.utMain {
  width: 80%;
  /* background-image: linear-gradient(to right, #f65f65, #ff50e8); */
  background-color: black;
  padding: 16px 32px;
  border-radius: 10px;
  margin: 0 auto !important;
  display: flex;
  flex-direction: column;
}

.UTinput {
  /* padding: 16px 32px !important; */
  border: none !important;
  margin: 0 !important;
}

.utMain p {
  font-size: 0.75rem;
  color: yellow;
  text-align: center;
  margin: 10px 0 0 0;
}

.UTform {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.UT.UTinput {
  /* margin-bottom: 0; */
}

.UTbtn {
  padding: 4px 10px;
  border-radius: 50px;
}

@media (max-width: 768px) {
  .utMain {
    width: 88%;
    /* background-image: linear-gradient(to right, #f65f65, #ff50e8); */
    background-color: black;
    padding: 16px 32px;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .UTinput {
    padding: 14px 32px;
  }
}
@media (max-width: 500px) {
  .utMain {
    width: 100%;
    padding: 7px 1px;
  }

  .UTinput {
    padding: 6px 4px;
    font-size: 0.5rem;
  }
  .UTbtn {
    padding: 4px 10px;
    border-radius: 50px;
    font-size: 0.5625rem;
    margin: 5px;
  }
}
