.otherPlayerLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0; 
  z-index: 200 !important;
}

.otherPlayerLabel > div {
  font-family: Helvetica, Arial;
  position: relative;
  background: #00000088;
  color: white;
  padding: 2px 12px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 15px;
  user-select: none;
}
