body {
    /* background-color: black; */
}

.loading-div {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border-top: 3px solid white;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    animation: mymove 0.5s linear infinite;
}

@keyframes mymove {
    from {
        transform: rotate(0deg);
     }
     to {
        transform: rotate(360deg);
     }
  }