.wrapper {
  padding: 50px 20px 5px 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 201;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper p {
  margin-top: 10px;
  color: red;
  font-weight: 600;
}

.wrapper h2 {
  text-align: center;
}
.wrapper input {
  margin-bottom: 15px;
  border: 1px solid;
  padding: 8px;
  border-radius: 11px;
}

.formInputs {
  display: none;
}

/* ::placeholder {
    text-align: center;
} */

.avtar_wrapper {
  display: grid;
  grid-template-columns: repeat(4, 90px);
  justify-content: center;
  justify-items: center;
  align-items: center;
  max-height: 390px;
  overflow-y: scroll;
}
.look_heading {
  margin: 22%;
  font-size: 1.125rem;
  color: black;
}

/* New Work */
/*
 *  STYLE 3
 */

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.avtarImage {
  margin: 5px 5px;
  /* height: 80px; */
  /* width: 80px; */
  justify-self: center;
  cursor: pointer;
  /* box-shadow: 4px 2px 5px rgb(0 0 0 / 20%); */
  border: solid 2px transparent;
  transition: all 0.2s ease-out;
  border-radius: 7px;
}

.avtarImage:hover {
  /* transform: scale(1.02); */
  box-shadow: 6px 5px 7px rgba(0, 0, 0, 0.3);
  border: solid 2px black;
  border-radius: 7px;
}

.avtarImage img {
  height: 100%;
  width: 100%;
  border-radius: 7px;
}

.formInputs:checked ~ .avtarImage {
  transform: scale(1.02);
  box-shadow: 1px 2px 8px rgb(254 83 209);
  border: solid 2px rgb(248 92 128);
}

.back-icon {
  height: 30px;
  width: 100%;
  position: absolute;
  top: 10px;
  left: 13px;
  cursor: pointer;
}

.back-icon_svg {
  height: 25px;
  width: 25px;
}

.step_btn {
  border-radius: 15px;
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  background-image: linear-gradient(90deg, #dc3545, #ff50e8);
  /* height: 40px; */
  line-height: 20px;
  padding: 11px 40px;
  margin: 20px 100px 0px;
  float: right;
  border: none;
}

/* Mobile css */

@media (max-width: 500px) {
  .wrapper {
    top: 50%;
  }
}

@media (max-width: 500px) {
  .avtar_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 95px);
    justify-content: center;
    justify-items: center;
    align-items: center;
    max-height: 300px;
    overflow-y: scroll;
  }
  .wrapper {
    width: 300px;
    height: 400px;
    top: 50%;
  }
  .look_heading {
    margin: 0px 50px;
    font-size: 15px;
    color: black;
  }
  .avtarImage {
    height: 69px;
    width: 59px;
  }
  .step_btn {
    font-size: 15px;
    padding: 7px 29px;
    margin: 20px 100px 0px;
  }
}

@media (max-height: 750px) {
  .wrapper {
    top: 53%;
  }
  .avtar_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 90px);
    justify-content: center;
    justify-items: center;
    align-items: center;
    max-height: 250px;
    overflow-y: scroll;
  }
  .step_btn {
    font-size: 0.875rem;

    padding: 7px 24px;
  }
  .avtarImage {
    height: 77px;
  }
}

@media (max-height: 450px) {
  .wrapper {
    top: 50%;
  }
  .avtar_wrapper {
    display: grid;
    grid-template-columns: repeat(5, 90px);
    justify-content: center;
    justify-items: center;
    align-items: center;
    max-height: 92px;
    overflow-y: scroll;
  }
  .step_btn {
    font-size: 0.875rem;

    padding: 7px 24px;
  }
  .avtarImage {
    height: 77px;
  }
}
