.main_1 {
    height: 100%;
    width: 100%;
    background: hsla(0, 0%, 0%, 0.678);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 201;
  }
  
  .explore_wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 30rem;
    background: #fff;
    border-radius: 0.25rem;
    padding: 1.5rem;
    box-shadow: 4px 5px 14px rgba(255, 255, 255, 0.3);
  
  }
  .confirm_Box_heading {
    font-size: 1.3rem;
    color: black;
  }
  
  .confirm__box_buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .confirm_yes {
    background-color: rgb(248, 248, 248) !important;
    border: 1px solid rgba(204, 204, 204, 0.5) !important;
    color: black;
    height: auto !important;
    width: auto !important;
    border-radius: 3px !important;
    padding: 5px 15px !important;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2) !important ;
    transition: all 0.2s ease-in;
  }
  
  .confirm_cancel {
    background-image: linear-gradient(to right, #ff512f, #dd2476);
    padding: 5px 15px !important;
    border-radius: 3px !important;
    height: auto !important;
    width: auto !important;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
    color: #f5f5f5;
    transition: all 0.2s ease-in;
    border: none;
  }
  
  .confirm_cancel:hover,
  .confirm_yes:hover {
    transform: translateY(-2px);
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
  }
  