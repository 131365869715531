

.main_profile_overlay {
  align-items: center;
  background-color: rgba(26, 25, 25, 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 201;
}

.profile_wrapper {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 201;
  width: 780px;
  border-radius: 5px;
  padding: 20px 20px 6px 20px;
}

.main_side_wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}

.profile_info {
  width: 35%;
}
.btnSectionRoom {
  display: flex;
  width: 100%;
  font-size: 1.125rem;
  font-weight: 600;
  gap: 8px;
  margin-top: 10px;
}

.profile_img img {
  height: 110px;
  width: 110px;
  border-radius: 50%;
}

.profile_details ul {
  list-style: none;
  padding: 0;
  font-weight: 500;
  line-height: 25px;
  font-size: 15px;
}

.avtar_name {
  margin: 6px 0 5px 0;
  font-size: 22px;
  font-weight: 600;
  padding: 0 0 0 5px;
}
.btn_edit {
  margin-top: 70px;
  display: flex;
  gap: 10px;
}

.edit_profile {
  position: relative;
  width: 100%;
  height: 40px;
  color: white;
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;

  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );

  transition: 0.3s ease-in-out;
  background-size: 200% auto;
  border: navajowhite;
}

.closeBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
  transition: all 0.05s ease-in;
  background: none;
  border: none;
}

.closeBtn:hover {
  transform: scale(1.1);
}

.closeBtn:hover svg {
  stroke: red;
}

.closeBtn svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 80%;
  width: 80%;
  stroke: #111;
  stroke-width: 2.5px;
  transition: all 0.05s ease-in;
}

.meta_world_room {
  display: flex;
  justify-content: center;
  align-items: center;
}

.meta_create {
  z-index: 201;
  height: 197px;
  width: 227.16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  padding: 20px;
  color: black;
  margin-left: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.create_btn_world {
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.Wrapper-name_worlds {
  margin: 0 0 20px 40px;
}

@media (max-width: 790px) {
  .profile_wrapper {
    width: 500px;
  }
  .profile_img img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }

  .edit_profile {
    height: 33px;
  }
  .avtar_name {
    margin: 4px 0 4px 0;
    font-size: 13px;
  }
  .emailAndCountry {
    font-size: 11px;
  }
  .main_side_wrapper {
    justify-content: start;
  }
  .profile_info {
    width: 40%;
  }
}

@media (max-width: 500px) {
  .profile_info {
    width: auto;
    display: flex;
    gap: 10px;
  }
  .profile_wrapper {
    width: 297px;
  }

  .main_side_wrapper {
    flex-direction: column;
  }
  .avtar_name {
    margin: 4px 0 4px 0;
    font-size: 20px;
  }
  .meta_create {
    height: 125px;
    padding: 10px;
  }
  .create_btn_world {
    font-size: 9px;
    font-weight: 800;
    color: black;
  }
}
