.logo {
  height: 60px;
  cursor: pointer;
}

.logo_link {
  text-decoration: none;
}
.navbar {
  display: flex;
  justify-content: space-between;
  background: #0000009e;
  align-items: center;
  height: 75px;
  z-index: 1000;
  padding: 8px 30px;
  /* color: white; */
  top: 0;
  position: sticky;
}

.nav-links {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  align-items: center;
  width: 75%;
  margin-bottom: 0;
}

.home,
.about,
.Contact,
.Services {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  padding: 7px 14px;
  margin: 0 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
}

/* .sinup {
   border: 1px solid black;
   border-radius: 5px;
 } */

.mobile-menu-icon {
  display: none;
}

.login-btn {
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: 500;
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  padding: 15px 16px;
  border-radius: 5px;
  margin-left: 10px;
  position: relative;
  z-index: 0;
  display: flex;
  width: auto;
  padding: 6px 15px;
  border: 1px solid #fff;
  border-radius: 100px;
  transition: 0.3s ease-in-out;
  font-size: 0.9375rem;
  cursor: pointer;
  transition: 0.5s;
  background-size: 200% auto;
  border: 1px solid transparent;
}

.login-btn:hover {
  position: relative;
  z-index: 10;
  display: flex;
  border: 1px solid #d4c7c7;
  transition: 0.3s ease-in-out;
  font-size: 0.9375rem;
  cursor: pointer;
  color: #fff;
}

.login-btn1 {
  color: white;
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: 500;
  background: none;
  padding: 15px 16px;
  border-radius: 5px;
  margin-left: 10px;
  position: relative;
  z-index: 0;
  display: flex;
  width: auto;
  padding: 6px 15px;
  border: 1px solid #fff;
  border-radius: 100px;
  transition: 0.3s ease-in-out;
  font-size: 0.9375rem;
  cursor: pointer;
  transition: 0.5s;
  background-size: 200% auto;
}

.login-btn1:hover {
  position: relative;
  z-index: 10;
  display: flex;
  border: 1px solid #d4c7c7;
  transition: 0.3s ease-in-out;
  font-size: 0.9375rem;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  color: #fff;
}

.list_sign {
  margin: 0 15px;
}

@media (max-width: 1170px) {
  .logo {
    height: 45px;
  }
  .navbar {
    display: flex;
    justify-content: space-around;
    background: #00000061;
    align-items: center;
    height: 70px;
  }

  .logo {
    display: flex;
    position: absolute;
    left: 12px;
    /* top: 15px; */
  }

  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    border-top: 1px solid rgba(0, 0, 0, 0.182);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    list-style: none;
    background: #00000061;
    color: black;
    height: 205px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-left: 0;
    animation: slideIn 0.7s ease;
    align-items: flex-start;
  }

  .nav-links {
    display: none;
  }

  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    right: 25px;
    /* padding: 7px; */
    border: none;
    outline: none;
    background: transparent;
    font-size: 25px;
    color: white;
  }

  .home,
  .about,
  .Services {
    display: block;
    width: 100%;
    color: white;
    text-align: start;
    transition: all 0.5s ease;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
  }

  .Contact {
    width: auto;
    color: black;
    display: block;
  }

  .login-btn {
    background-image: linear-gradient(to right, #fff 0%, #fff 51%, #fff 100%);
    color: black;
    font-weight: 600;
    font-size: 1rem;
    padding: 8px 18px;
    margin-bottom: 10px;
  }

  .list_sign {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  .sign {
    width: auto;
    color: white;
    text-align: start;
    transition: all 0.5s ease;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
    border: none;
    background: none;
  }
}
