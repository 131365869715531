* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.cards {
  /* max-width: 1300px; */
  margin: 0 auto;
  text-align: center;
  padding: 30px 0px;
}
.cards h1 {
  font-size: 2.5rem;
  margin: 0 0 30px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.services {
  display: flex;
  align-items: center;
  width: 20rem;
}
.content {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  margin: 20px;
  /* padding: 1rem; */
  /* border: 1px solid #00000038; */
  border-radius: 4px;
  transition: all 0.3s ease;
  background: #fff;
  /* border: 1px solid rgba(255, 255, 255, 0.13); */
  box-shadow: rgb(0 0 0 / 18%) 0px 3px 6px;
  border-radius: 8px;
  color: #000;
  /* max-width: 17rem; */
}
.card-section h3 {
  font-size: 2.5rem;
  font-weight: 900;
  text-align: center;
  /* position: relative; */
  /* padding: 0 0 50px 0; */
}

.content:hover {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: 20px;
  /* padding: 1rem; */
  /* border: 2px solid black; */
  border-radius: 4px;
  transition: all 0.3s ease;
  background: #fff;
  /* border: 1px solid rgba(255, 255, 255, 0.13); */
  box-shadow: rgb(0 0 0 / 38%) 0px 8px 15px;
  border-radius: 8px;
  color: #000;
  /* max-width: 17rem; */
}

.content .fab {
  font-size: 4.375rem;
  margin: 16px 0;
}
.content > * {
  flex: 1 1 100%;
}
/* .content:hover {
  color: white;
} */
.content:hover a {
  border-color: white;
  background: white;
}
.card-info {
  padding: 0px 0px 15px 0px;
}
.card-btn {
  display: flex;
}

.exp-btn {
  width: 100%;
  display: flex;
  column-gap: 20px;

  justify-content: center;
  align-items: center;
}
.r-name {
  text-align: left;
  font-size: 1rem;
  font-weight: 300;
  color: #746c6ce3;
  padding: 10px 5px 10px 15px;
}
/* .content-1:hover {
  border-color: #4267b2;
  background: #4267b2;
} */
.content-1:hover a {
  color: #4267b2;
}
.content-2:hover {
  border-color: #075e54;
  background: #075e54;
}
.content-2:hover a {
  color: #075e54;
}
.content-3:hover {
  border-color: #ff0000;
  background: #ff0000;
}
.content-3:hover a {
  color: #ff0000;
}
.content h2 {
  font-size: 1.875rem;
  margin: 16px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.content .post_info {
  margin: 22px 0;
  background: black;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid black;
  padding: 15px 0;
  border-radius: 25px;
  transition: 0.3s ease;
}

.content p {
  margin-bottom: 0;
}
.content a:hover {
  border-radius: 4px;
}

.profile img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 1px solid white;
  margin: 0px 10px 0px 0px;
}
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 30px !important;
  height: 30px !important;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: none;
  border-radius: 50% !important;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 2.125rem;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 1.875rem;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 2rem !important;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-c {
  font-size: 1.25rem;
  color: #ee4390c9;
}
.vlike {
  padding: 8px 10px;
  border-radius: 15px;
  background-image: linear-gradient(133deg, #ff6144, #ef458d);
  color: #fff;
  width: 70%;
  /* border: 1px solid #f8f9fb; */
  /* background: no-repeat; */
  /* color: #000; */
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.1);
  border: none;
}

.btn_like {
  background: linear-gradient(
    270deg,
    rgb(0, 163, 255) -36.05%,
    rgb(3, 255, 236) 46.96%,
    rgb(0, 255, 178) 162.7%
  );
  border-radius: 3rem;
}

.btn_visit {
  background: linear-gradient(
    270deg,
    rgb(0, 163, 255) -36.05%,
    rgb(3, 255, 236) 46.96%,
    rgb(0, 255, 178) 162.7%
  );
  border-radius: 3rem;
}
.btn_show_post {
  background: none;
  color: #000;
  border-radius: 2rem;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0.7rem 2rem;
  border: 2px solid;
}

.btn_show_post:hover {
  background-color: #ff5d53;
  border-color: #e7e2e2;
  /* -webkit-filter: invert(100%); */
  /* filter: invert(100%); */
  /* font-weight: 500; */
  color: #fff;
}
.poster_image {
  width: 100%;
  height: 16rem;
  padding: 0px;
}
.vicon {
  position: absolute !important;
  color: #fff;
  margin-left: 6px;
}
.card-name {
  padding: 5px 5px 10px 6px;
  text-align: right;
}
.hr-c {
  color: #acb0b7;
  margin-top: 0px;
  margin-bottom: 0px;
}
.poster_image img {
  width: 100%;
  height: 100%;
  border-radius: 7px 7px 0px 0px;
}
.i-gap {
  display: flex;
  column-gap: 2px;
  margin-top: 6px;
}
.i-gap2 {
  display: flex;
  column-gap: 7px;
  padding: 10px;
  border-top: 1px solid #ddcece;
  margin-top: 10px;
}

.like_button {
  cursor: pointer;
}

/* Tabs Started */
.filter-tabs {
  display: grid;
  width: auto;
  align-items: center;
  justify-content: center;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 0 auto;
  /* overflow-x: scroll; */
}

.MM_createBtn {
  background-image: linear-gradient(to right, #ff512f, #dd2476);
  color: white;
  padding: 10px 20px;
  border-radius: 100px;
  font-weight: 600;
  font-size: 1rem;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);

  transition: all 0.2s ease-in;
  backface-visibility: none;
  border: none;
}

.MM_createBtn:hover {
  transform: scale(1.02);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  /* padding: 15px 30px; */
}

.MM_noWorld {
  text-align: center;
}

.MM_noWorld p {
  color: black;
  font-weight: 400;
  font-size: 1.375rem;
  padding: 10px;
}

@media (max-width: 1600px) {
  .explore {
    /* margin-top: 50px; */
    padding: 40px 40px !important;
  }
}
@media (max-width: 1200px) {
  .services {
    display: flex;
    flex-direction: column;
  }
  .filter-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    /* overflow-x: scroll; */
  }

  .explore {
    padding: 40px 40px !important;
  }
}

@media (max-width: 900px) {
  .filter-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .services {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  .explore {
    /* margin-top: 54px!important; */
    padding: 10px 10px !important;
  }
}
@media (max-width: 500px) {
  .filter-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .explore {
    padding: 10px 10px !important;
  }
}

@media (max-width: 400px) {
  .explore {
    margin-top: 54px !important;
    padding: 10px 10px !important;
  }
}
/* .filter-tabs {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 30px;
} */

.filterBtn--tab {
  display: inline-block;
  color: #525252;
  min-width: 155px;
  padding: 6px 9px;
  font-weight: 500;
  border-radius: 500px;
  font-size: 0.875rem;
  border: 1px solid #ff5f7f7d !important;
  background: #fff;
  box-shadow: 2px 6px 9px #ffffff87;
  /* width: 70%; */
}
.filterBtn--tab:hover {
  display: inline-block;
  min-width: 155px;
  padding: 6px 9px;
  font-weight: 500;
  border-radius: 500px;
  font-size: 0.875rem;
  border: 1px solid #ff5f7f7d !important;
  box-shadow: 2px 6px 9px #ffffff87;
}
.filterBtn--tab_active {
  border: 1px solid #f65e6d !important;
  background: none;
  color: #fff;
  background-image: linear-gradient(90deg, #f65f65, #ff50e8);
  box-shadow: 0px 3px 8px #00000024;
}
/* Tabs Ended */

/* Loading Spinner */

.spinnerDiv {
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fadeInCard {
  animation: fadeIn;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  padding: 0px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.explore {
  /* margin-top: 73px; */
  padding: 40px 170px;
  width: 100%;
  background: #e5e4e4;
}

/* Grid Setup 08/03/23 */

.PC_main-div {
  display: grid;
  grid-template-columns: 60px 60px;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  row-gap: 20px;
}

.PC_profile-img {
  height: 45px;
  width: 45px;
}

.PC_profile-image {
  border-radius: 1000px;
}

.PC_name-div,
.PC_likes,
.PC_visits {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PC_room-name {
  font-size: 0.75rem;
  text-align: center !important;
}
.scroll-inner::-webkit-scrollbar {
  width: 10px;
}
.scroll-inner::-webkit-scrollbar:horizontal {
  height: 10px;
}
.scroll-inner::-webkit-scrollbar-track {
  background-color: rgba(204, 204, 204, 0.3);
}
.scroll-inner::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(204, 204, 204, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.btn_show_post--div {
  text-align: center;
}

.pagination_wrapper {
  position: relative;
  margin: 40px 0 0 0;
  padding: 40px 0 0 0;
}

@media (max-width: 500px) {
  .filterBtn--tab {
    border: 1px solid #ff5f7f7d !important;
    border-radius: 500px;
    box-shadow: 2px 6px 9px #ffffff87;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    min-width: 90px;
    padding: 6px 9px;
  }
  .filterBtn--tab:hover {
    min-width: 90px;
  }
}

@media (max-height: 450px) {
  .explore {
    margin-top: 60px;
  }
}
