.main_1 {
  height: 100%;
  width: 100%;
  background: #000000ad;
  position: fixed;
  z-index: 201;
  top: 0;
  left: 0;
}
.leaveRoom_Wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 30rem;
  background: #fff;
  border-radius: 0.25rem;
  padding: 32px 32px 10px 32px;
}

.checkbox-form {
  margin: 20px 0;
}

.checkbox-form div {
  margin-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thirdInput {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.LeaveInput {
  display: flex;
  width: auto;
  gap: 10px;
}

.LeaveInput label {
  color: black;
}
.leave_section h4 {
  font-size: 1.2rem;
}

/*  */

.checkmark {
  position: relative;
  background-color: rgba(16, 16, 16, 0.5);
  height: 20px;
  width: 20px;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: -1px;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  transform-origin: 0px 10px;
  border-radius: 4px;
  margin: -1px 10px 0px 0px;
  padding: 0px;
  box-sizing: border-box;
}

.container input:checked ~ .checkmark {
  background-color: rgba(245, 24, 24, 0.5);
  height: 20px;
  width: 20px;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: -1px;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  transform-origin: 0px 10px;
  border-radius: 4px;
  margin: -1px 10px 0px 0px;
  padding: 0px;
  box-sizing: border-box;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 0.45em;
  top: 0.25em;
  width: 0.25em;
  height: 0.5em;
  border: solid white;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
  transition: all 500ms ease-in-out;
}
.leave_section h4 {
  color: black;
}

.extra_input {
  font-size: 1rem;
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #ccc;
  color: black;
  width: 100%;
  background-color: transparent;
  transition: border-color 0.3s ease-in-out;
}

.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #08aeea;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.extra_input:focus {
  border-color: #08aeea;
  outline: none;
}

.extra_input:focus + .underline {
  transform: scaleX(1);
}

.confirm__box_buttons {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.confirm_yes {
  background-color: rgb(248, 248, 248);
  border: 1px solid rgba(204, 204, 204, 0.5);
  color: black;
  padding: 5px 15px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in;
}

.confirm_cancel {
  background-image: linear-gradient(to right, #ff512f, #dd2476);
  padding: 5px 15px;
  border-radius: 3px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  color: #f5f5f5;
  transition: all 0.2s ease-in;
  border: none;
}

.confirm_cancel:hover,
.confirm_yes:hover {
  transform: translateY(-2px);
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
}

.skip_and_Leave {
  background: none;
  font-size: 0.75rem;
  color: black;
  border: none;
}

.closeBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
  box-shadow: 0 2px 2px black !important;
  transition: all 0.05s ease-in;
  border: none;
}

.closeBtn:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 5px black;
}

.closeBtn:hover svg {
  stroke: red;
}

.closeBtn svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);

  height: 80%;
  width: 80%;
  stroke: #111;
  stroke-width: 2.5px;

  transition: all 0.05s ease-in;
}
