.main_pricing {
  background: #000000ad;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 10px;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
  color: white;
}

.form-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-input-group {
  display: flex;
  flex-direction: column;
  margin: 0px 15px;
}

.label_data {
  margin-bottom: 5px;
  color: white;
}

.input_pricing {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-btn {
  color: #fff;
  letter-spacing: 1px;
  font-weight: 500;
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );

  border-radius: 5px;
  position: relative;
  z-index: 0;

  width: auto;
  margin: auto;
  padding: 6px 33px;
  border: 1px solid #fff;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
  font-size: 0.9375rem;
  cursor: pointer;
  transition: 0.5s;
  background-size: 200% auto;
  border: navajowhite;
  margin: 10px 0px;
}

.form-section1 {
  text-align: center;
}

.svg_wrap {
  height: 25px;
  width: 25px;
  background-color: aliceblue;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
  background-image: linear-gradient(90deg, #f65f65, #ff50e8);
  color: #fff;
}

.svg_wrap svg {
  stroke: #fff;
  transform: rotate(45deg);
  height: 25px;
  width: 25px;
}

@media (max-width: 768px) {
  .form-section {
    flex-direction: column;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    position: absolute;
    /* width: 250px; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 10px;
  }
  form {
    width: 100% !important;
  }
  .form-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .input_pricing {
    padding: 0px;
    margin-bottom: 5px;
  }
  .submit-btn {
    margin: 5px;
    padding: 4px 33px;
    font-size: 0.75rem;
  }
}
