.toast_container {
  position: fixed;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  z-index: 201;
}

.result-success-mode-dark-palette {
  width: 438px;
  height: 143px;
  position: relative;
  overflow: hidden;
}

.message-frame {
  background: #0c7040;
  border-radius: 15px;
  width: 438px;
  height: 100px;
  position: absolute;
  left: 0px;
  top: 42px;
  overflow: hidden;
}
.icon-24-close {
  position: absolute;
  right: 24px;
  top: 24px;
  overflow: visible;
}
.green-bubbles {
  position: absolute;
  left: -31px;
  top: 39px;
  overflow: visible;
}
.message {
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 130px;
  top: calc(50% - 46px);
}
.header {
  color: #ffffff;
  text-align: left;
  font: 500 27px "Poppins", sans-serif;
  position: relative;
  width: 244px;
  height: 35px;
  margin-top: 10px;
}
.text {
  color: #ffffff;
  text-align: left;
  font: 400 13px/130% "Poppins", sans-serif;
  position: relative;
  width: 244px;
}
.icon-78-success {
  width: 78px;
  height: 78px;
  position: absolute;
  left: 20px;
  top: 0px;
  overflow: hidden;
}
.union3 {
  border-radius: 0px;
  position: absolute;
  left: 4.68px;
  top: 8px;
  overflow: visible;
}
.icon-32-check {
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 14px);
  overflow: visible;
}

/*  */

.message-frame-red {
  background: #c72c41;
  border-radius: 15px;
  width: 438px;
  height: 100px;
  position: absolute;
  left: 0px;
  top: 42px;
  overflow: hidden;
}
.red-bubbles {
  position: absolute;
  left: -64px;
  top: 22px;
  overflow: visible;
}
.icon-78-fail {
  width: 78px;
  height: 78px;
  position: absolute;
  left: 20px;
  top: 0px;
  overflow: hidden;
}

@media (max-width: 500px) {
  .message-frame-red {
    width: 302px;
    height: 72px;
    left: 14%;
  }

  .message-frame {
    width: 302px;
    height: 72px;
    left: 14%;
  }
  .icon-78-success {
    left: 72px;
  }
  .red-bubbles {
    left: -131px;
    top: 59px;
  }
  .message {
    left: 53px;
  }
  .header {
    font: 500 20px "Poppins", sans-serif;
    height: 23px;
    margin-top: 22px;
  }
  .text {
    font: 401 11px/132% "Poppins", sans-serif;
  }

  .icon-24-close {
    right: 10px;
    top: 9px;
    height: 19px;
    z-index: 1000;
  }
}
