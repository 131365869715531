.myPlayerLabel {
 
  z-index: 200 !important;
}

.myPlayerLabel > div {
  font-family: Helvetica, Arial; 
  background: #00000088;
  color: white;
  padding: 2px 12px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 15px;
  user-select: none;  
}
