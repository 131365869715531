.loading_wrapper {
  height: 100%;
  width: 100%;
  background-color: black;
  position: absolute;
  top: 0;
}

.loading-div {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border-top: 3px solid white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: mymove 0.5s linear infinite;
}

@keyframes mymove {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.FR_avtar_image {
  margin: 0 auto;
  height: 110px;
  width: 110px;
  border-radius: 15px;
  justify-self: center;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3);
  border: solid 2px transparent;
  transition: all 0.2s ease-out;
  border-radius: 50%;
}

.FR_avtar_image:hover {
  transform: scale(1.02);
  box-shadow: 9px 9px 17px rgba(0, 0, 0, 0.3);
  border: solid 2px black;
}
.meta_height {
  height: 530px;
  overflow-y: scroll;
}
.meta-box {
  /* background: #0000004f; */
  /* border-radius: 5px; */
  padding: 20px;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-image: linear-gradient(90deg, #f65f65, #ff50e8);
}

.avatar-photo {
  text-align: center;
}
.avatar-photo img {
  width: 188px;
  text-align: center;
  height: 100%;
  margin-top: -30px;
}
.avatar-photo label {
  color: #fff;
  font-size: 1.375rem;
  font-weight: 600;
  padding: 10px;
}
.adaptive-glass {
  --glass-lightness: 100%;
  background: hsl(0 0% var(--glass-lightness) / 50%);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
}

.showPassword{
  position: absolute;
  right: 6px;
}

@media (prefers-color-scheme: dark) {
  .adaptive-glass {
    --glass-lightness: 0%;
  }
}

@supports not (
  (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))
) {
  .adaptive-glass {
    background: hsl(0 0% var(--glass-lightness) / 90%);
  }
}

figure {
  --on-glass-primary: hsl(220 50% 20%);
  --on-glass-secondary: hsl(220 40% 30%);

  border-radius: 0.5ch;
  overflow: hidden;

  /*  https://shadows.brumm.af/  */
  box-shadow: 0 3px 2px hsl(0 0% 0% / 2%), 0 7px 5px hsl(0 0% 0% / 3%),
    0 13px 10px hsl(0 0% 0% / 4%), 0 22px 18px hsl(0 0% 0% / 5%),
    0 42px 33px hsl(0 0% 0% / 6%), 0 100px 80px hsl(0 0% 0% / 7%);
}

@media (prefers-color-scheme: dark) {
  figure {
    --on-glass-primary: hsl(220 50% 90%);
    --on-glass-secondary: hsl(220 30% 75%);

    margin: 0 0 2.5rem;
  }
}

figure img {
  display: block;
  inline-size: 100%;
  block-size: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

figcaption {
  display: grid;
  grid: [stack] 1fr / [stack] 1fr;
  position: relative;
}

figcaption > * {
  grid-area: stack;
}

figcaption > img {
  position: absolute;
  inset: 0;
  /*   reflect hero image for a nice effect!   */
  transform: scaleY(-1);
}

figcaption > section {
  z-index: 3;
  padding-inline: 2ch;
  padding-block: 2ch 1ch;
  color: var(--on-glass-primary);
  display: grid;
  gap: 1ch;
}

@media (prefers-color-scheme: dark) {
  figcaption > section {
    text-shadow: 0 1px 0 hsl(0 0% 0% / 20%);
  }
}

figcaption h3 {
  font-size: clamp(1.25rem, calc(1rem + 2vw), 1rem);
  font-weight: 600;
  text-transform: uppercase;
}

figcaption date {
  color: var(--on-glass-secondary);
}

.body_wrapper_reg {
  padding-top: 5%;
  background-position: center;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  top: 0;
  background-image: url(../../../assets/images/NNN-02.jpg);
  overflow-y: scroll;
}

.body_wrapper {
  padding-top: 15vh;
  background-position: center;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  top: 0;
  overflow-y: scroll;
  background: rgb(120 129 147);
}

@-webkit-keyframes Background {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Background {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Background {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.RF_avtar_wrapper {
  display: grid;
  flex-wrap: wrap;
  width: 84%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  margin-bottom: 18px !important;
  margin: auto;
}
.RF_avtar_wrapper label {
  display: grid;
}

.RF_input {
  display: none;
}

.RF_input:checked ~ .FR_avtar_image {
  transform: scale(1.02);
  box-shadow: 1px 2px 8px rgb(254 83 209);
  border: solid 2px rgb(248 92 128);
}
.RC_main_wrapper {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #00000075;
  border-radius: 15px;
  /* padding: 18px 20px 33px 18px; */
}

@media (max-width: 1250px) {
  .RC_main_wrapper {
    width: 65%;
  }
}
@media (max-width: 1060px) {
  .RC_main_wrapper {
    width: 90%;
  }
}
.step_btn {
  position: absolute;
  border-radius: 11px;
  font-size: 1.375rem;
  font-weight: 600;
  color: white;
  /* float: right; */
  background-image: linear-gradient(90deg, #f65f65, #ff50e8);
  height: 46px;
  line-height: 19px;
  right: 23%;
  margin-top: 11px;
  padding: 10px 50px;
}

.room_creation_input {
  display: grid;
  background-color: #fff;
  box-shadow: 3px 3px 4px rgb(0, 0, 0, 0.32);
  padding: 11px 26px;
  margin: auto;
  width: 90%;
  /* text-align: center; */
  border-radius: 6px;
  /* border: 1px solid transparent; */
  transition: all 0.1s ease-in;
}
/* .room_creation_input:focus {
  transform: scale(1.05);
  border: 1.5px solid #0f0c29;
  outline: none !important;
  box-shadow: 5px 5px 10px rgb(0, 0, 0, 0.32);
} */

::placeholder {
  color: rgb(3, 13, 22) !important;
  font-weight: 300;
  font-size: 0.875rem;
  letter-spacing: normal !important;
}

.RC_heading {
  font-size: 2.5rem;
  font-weight: 800;
  color: #fff;
  text-align: center;
  text-shadow: 3px 3px 6px rgb(0, 0, 0, 0.32);
  padding: 10px;
}
.R_inputs {
  display: grid;
  row-gap: 1px;
  grid-template-columns: 1fr;
}

/* Slider */

.RF_slider_wrapper {
  margin: 20px 0;
}

/* VALIDATION */

.R_validation_warning {
  margin: 0 10%;
  padding: 0;
  color: #fff700;
  text-align: left;
  font-size: 0.75rem;
}

.sliderdiv {
}

.cbox {
  display: flex;
  color: #fff;
  margin-left: 15px;
}

@media (max-width: 786px) {
  .RC_main_wrapper {
    padding: 20px 25px;
    width: 90%;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #00000075;
    border-radius: 15px;
  }
  .centerView {
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .FR_avtar_image img {
    height: 50px;
    width: 50px;
  }
  .FR_avtar_image {
    height: 50px;
    width: 50px;
    border-radius: 15px;
    justify-self: center;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3);
    border: solid 2px transparent;
    transition: all 0.2s ease-out;
    border-radius: 50%;
  }
  .room_creation_input {
    display: grid;
    background-color: #fff;
    box-shadow: 3px 3px 7px rgb(0, 0, 0, 0.32);
    padding: 6px 5px;
    margin: auto;
    width: 100%;
    /* text-align: center; */
    border-radius: 4px;
    border: 1.5px solid transparent;
    transition: all 0.1s ease-in;
    justify-self: center;
  }
  .R_inputs {
    display: grid;
    row-gap: 1px;
    grid-template-columns: 1fr;
  }
}

.submit_button {
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  float: right;
  background-image: linear-gradient(90deg, #f65f65, #ff50e8);
  height: 40px;
  line-height: 20px;
  right: 15%;
  margin-right: 7%;
  padding: 10px 25px 10px 25px;
  margin-top: 1rem;
}

.submit_div {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .RC_main_wrapper {
    margin-top: 35px;
  }
  .submit_div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit_button {
    margin: 1rem 0;
  }

  .meta_height {
    height: 530px;
    overflow-y: scroll;
  }
  .avatar-photo img {
    width: 100px;
    text-align: center;
    height: 100%;
    margin-top: 0px;
  }
  .RC_heading {
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    text-shadow: 3px 3px 6px rgb(0, 0, 0, 0.32);
    padding: 10px;
  }
  ::placeholder {
    color: rgb(3, 13, 22) !important;
    font-weight: 300;
    font-size: 0.625rem;
    letter-spacing: normal !important;
  }
  .mt-5 {
    margin-top: 3rem !important;
  }
}
@media (max-width: 700px) {
  .meta-box {
    margin-top: 20%;
  }

  .body_wrapper {
    padding-top: 0vh;
  }
}

/*     <-------------------------------------------new code------------------------------------------>   */

.main_wrapper {
  padding-top: 5%;
  background-position: center;
  background-size: cover;
  position: absolute;
  min-height: 100%;
  width: 100%;
  z-index: 3;
  top: 0;
  background-image: url("../../../assets/images/Bg4.jpg");
  overflow-y: scroll;
}

.profileEdit {
  display: flex;
  justify-self: flex-start;
}

.Info {
  cursor: pointer;
  color: #f65f65;
}

.image-container {
  position: relative;
  display: inline-block;
}

.edit-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.edit-icon img {
  width: 30px; /* Customize the icon size as needed */
  height: 30px;
}

.form_main {
  width: 350px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  padding: 30px 30px 30px 30px;
  border-radius: 15px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.062);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading {
  margin-top: 10px;
  font-size: 25px;
  font-weight: 600;
  text-underline-offset: black;
}
.image-container img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: greenyellow;
}

.inputContainer {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputIcon {
  position: absolute;
  left: 10px;
}

.inputField {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgb(173, 173, 173);
  border-radius: 30px;
  margin: 10px 0;
  color: black;
  font-size: 0.8em;
  font-weight: 500;
  box-sizing: border-box;
 
  
  padding: 0 38px 0 31px 
}

.inputField:focus {
  outline: none;
  border-bottom: 2px solid rgb(199, 114, 255);
}

.inputField::placeholder {
  color: rgb(80, 80, 80);
  font-size: 1em;
  font-weight: 500;
}

.registration_submit_button {
  position: relative;
  width: 100%;

  height: 40px;
  color: white;
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 30px;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;

  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );

  transition: 0.3s ease-in-out;
  background-size: 200% auto;
  border: navajowhite;
}

.signupContainer {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.signupContainer p {
  font-size: 0.9em;
  font-weight: 500;
  color: black;
}
.p {
  color: #000;
  font-size: 0.875rem;
  margin: 5px 0;
  text-align: center;
}

.span {
  color: #2d79f3;
  font-size: 0.875rem;
  margin-left: 5px;
}

.signupContainer a {
  font-size: 0.7em;
  font-weight: 500;
  background-color: #2e2e2e;
  color: white;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 20px;
}

.cBox {
  color: rgb(7, 7, 7);
  font-size: 11px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}
#checkbox {
  width: 16px;
  color: #fff;
  margin: 0;
}

@media (max-width: 768px) {
  .form_main {
    width: 307px !important;
  }
}

@media (min-height: 500px) and (max-height: 750px) {
  .form_main {
    top: 53%;
  }
}
