.MM_rooms {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3);
  border: solid 1.5px transparent;
  transition: all 0.2s ease-out;
}

.body_wrapper {
  background-image: linear-gradient(to right bottom, #fbd786, #f7797d);
  position: absolute;
  height: 100%;
  width: 100%;
}

.MM_container {
  background: #0000004d;
  align-items: center;
  width: 50%;
  padding: 16px 32px;
  border-radius: 15px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MM_container p {
  color: white;
  font-size: 2rem;
  font-weight: 1000;
  text-align: center;
  text-shadow: 3px 3px 6px rgb(0, 0, 0, 0.32);
  padding: 10px;
}

.MM_rooms img {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.MM_rooms:hover {
  transform: scale(1.02);
  box-shadow: 9px 9px 17px rgba(0, 0, 0, 0.3);
  border: solid 1.5px black;
}

.MM_rooms_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  justify-content: space-between;
  align-self: stretch;
}

.MM_rooms_div p {
  color: white;
  font-size: 0.9375rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.MM_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
}

.MM_createBtn {
  background-image: linear-gradient(to right, #ff512f, #dd2476);
  color: white;
  padding: 10px 20px;
  border-radius: 100px;
  font-weight: 600;
  font-size: 1rem;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);

  transition: all 0.2s ease-in;
  backface-visibility: none;
  border: none;
}

.MM_createBtn:hover {
  transform: scale(1.02);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  /* padding: 15px 30px; */
}

.MM_noWorld {
  text-align: center;
}

.MM_noWorld p {
  color: white;
  font-weight: 400;
  font-size: 0.9375rem;
}
