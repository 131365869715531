.wrapper {
  background: white;
  border-radius: 8px;
  padding: 17px;
  justify-content: space-between;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 15px;
  position: relative;
  margin: 4px 0 10px 5px;
}

.left_section {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin: auto;
  padding: 4px;
  border: 2px solid white;
  cursor: pointer;
  position: relative;
}

.left_section::after {
  content: "\270E";
  display: inline-block;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 32%;
  left: 10%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 10;
  font-weight: 700;
  color: #080808;
  font-size: 1.25rem;
}

.left_section img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.left_section img:hover {
  filter: blur(2px);
}

.right_section {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  margin: auto;
}

.radio-btn-active,
.radio-btn {
  height: 18px;
  width: 18px;
  border: 2px solid black;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
}

.radio-btn-active::after {
  content: "";
  display: block;
  height: 8px;
  width: 8px;
  background-color: black;
  border-radius: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.status_div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 35px;
}

.radio-wrap {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 0;
}

.radio-wrap p {
  display: inline-block;
  margin: 0;
}

.room_name {
  font-size: 0.9375rem;
  font-weight: 600;
  color: black;
}

.room_name {
  font-size: 1rem;
}

.Visit_btn {
  position: absolute;
  top: 4%;
  right: 2%;
  padding: 1px 9px;
  font-size: 12px;
  border: none;
  background-image: linear-gradient(to right, #ff512f 0%, #ff512f 100%);
  color: white;
}

@media (max-width: 791px) {
  .wrapper {
    padding: 9px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
  }
  .right_section {
    margin: auto;
  }
  .left_section {
    margin: auto;
  }
}
@media (max-width: 500px) {
  .wrapper {
    flex-direction: column;
    width: auto;
    padding: 43px;
  }
  .right_section {
    margin: auto;
  }
  .left_section {
    margin: auto;
    height: 50px;
    width: 50px;
  }
  .room_name {
    font-size: 0.9125rem;
    font-weight: 600;
    color: black;
    /* margin-top: 5px; */
  }
  .status_div {
    gap: 12px;
  }
  .radio-wrap p {
    font-size: 13px;
  }
  .status_div {
    padding: 2px;
  }
}
