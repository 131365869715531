.login-container {
  background-image: url("../../assets/images/Bg4.jpg");
  background-position: center;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.login_body {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form_login {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  padding: 23px;
  width: 422px;
  border-radius: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.form button {
  align-self: flex-end;
}

.flex-column > label {
  color: #151717;
  font-weight: 600;
}

.inputForm {
  border: 1.5px solid #00000042;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
  position: relative;
}

.login_input {
  margin-left: 10px;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0px 38px 0 0px;
}

.input:focus {
  outline: none;
}

.inputForm:focus-within {
  border: 1.5px solid #2d79f3;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-around;
}

.flex-row > div > label {
  font-size: 0.875rem;
  color: black;
  font-weight: 400;
}

.span {
  font-size: 0.875rem;
  margin-left: 5px;
  color: #2d79f3;
  font-weight: 500;
  cursor: pointer;
}

.button-submit {
  margin: 20px 0 10px 0;
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );

  transition: 0.3s ease-in-out;
  background-size: 200% auto;
  border: navajowhite;
  color: white;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  cursor: pointer;
}

.p {
  text-align: center;
  color: black;
  font-size: 0.875rem;
  margin: 5px 0;
}

.btn_login {
  margin-top: 10px;
  padding: 10px 32px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  border: 1px solid #ededef;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  color: black;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.fb-login-btn {
  pointer-events: none !important;
}
.fbbtn {
  cursor: none;
}
.btn_login:hover {
  border: 1px solid #2d79f3;
}

.login_heading {
  text-align: center;
  color: black;
}

.login_heading_Main {
  text-align: center;
  color: black;
  font-size: 28px;
  margin: 0;
  font-weight: 600;
}

.showPassword{
  position: absolute;
  right: 6px;
}

@media (max-width: 608px) {
  .form_login {
    width: 330px !important;
    gap: 10px;
  }
  .btn_login {
    margin-top: 10px;
    padding: 10px 20px;
    height: 45px;
  }
  .login_input::placeholder {
    font-size: 0.75rem;
  }
}

@media (max-height: 800px) {
  .inputForm {
    height: 38px;
  }
  .button-submit {
    margin: 0px 0 10px 0;
    font-size: 0.75rem;
    height: 40px;
    width: 100%;
    cursor: pointer;
  }

  .form_login {
    width: 310px !important;
  }
  .flex-column > label {
    display: none;
  }
  .form_login {
    padding: 20px;
  }

  .login_body {
    top: 55%;
  }
  .p {
    margin-bottom: auto;
  }
  .line {
    margin-bottom: auto;
  }

  .btn_login {
    margin-top: auto;
    padding: 10px 20px;
    height: 43px;
    font-size: 12px;
  }
}

@media (max-height: 610px) {
  .inputForm {
    height: 34px;
  }

  .form_login {
    width: 300px !important;
  }
  .flex-column > label {
    display: none;
  }

  .login_body {
    top: 56%;
  }

  .btn_login {
    margin-top: auto;
    padding: 9px 21px;
    height: 43px;
    gap: 9px;
    font-size: 12px;
  }
  .login_input::placeholder {
    font-size: 0.8rem;
  }
  .p {
    font-size: 0.8rem;
    margin: 0px 0;
  }
}

@media (max-height: 500px) {
  .inputForm {
    height: 34px;
  }

  .login_heading {
    display: none;
  }
  .form_login {
    width: 275px !important;
  }

  .login_body {
    top: 58%;
  }
  .login_heading {
    display: none;
  }

  .btn_login {
    margin-top: auto;
    padding: 0px 16px;
    height: 36px;
    font-size: 12px;
  }

  .flex-column > label {
    display: none;
  }

  .login_input::placeholder {
    font-size: 0.75rem;
  }
}

@media (max-height: 390px) {
  .inputForm {
    height: 34px;
  }

  .login_heading {
    display: none;
  }
  .form_login {
    width: 254px !important;
  }

  .login_body {
    top: 58%;
  }

  .btn_login {
    margin-top: auto;
    padding: 0px 12px;
    height: 34px;
    font-size: 11px;
  }

  .flex-column > label {
    display: none;
  }

  .login_input::placeholder {
    font-size: 0.75rem;
  }

  .p {
    font-size: 0.7rem;
    margin: 0px 0;
  }
  .line {
    display: none;
  }
}
