
 

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;

}

code {
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar-thumb {
  background: rgb(62, 62, 62);
  border-radius: 10px;
  margin-right: 6px;
}


::-webkit-scrollbar {
  width: 5px;
  background: black;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: white;

}

body {
  background: white;
}

a {
  cursor: pointer;
  /* position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translate3d(-50%, 0, 0); */
}
