.like_button {
    cursor: pointer !important;
    font-size: 22px;
    background-color: #00000085;
    color: #fffffff5 !important;
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    display: flex;
    justify-content:center;
    align-items: center;
  }
  
  
  @media (max-width: 768px) {
    .like_button {
      font-size: 1.9375rem;
      background-color: white;
      color: red !important;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      padding: 6px 4px;
    }
  }
  