/* 
screenButtonContainer 1
  */
.screenButtonContainer {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -51%);
  /* border: 2px solid blue; */
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  z-index: 201;
}
.screenButton {
  height: 35px;
  width: 35px;
  font-size: 15px;
  border-radius: 50%;
  background: #0000008f;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 201;
  margin: 0px 5px;
  padding: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border: 1px solid;
  border-color: #f8f9fa26;
}
.selfmute {
  background-color: green;
}
