.wrapper_password {
  width: 90vw;
  margin: 0 auto;
  margin-top: 8rem;
  max-width: 30rem;
  background: #fff;
  border-radius: 0.25rem;
  padding: 2rem;
  box-shadow: 4px 5px 14px rgba(255, 255, 255, 0.3);

}

.inputpassword {
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  background-color: aliceblue;
  border: 1px solid black;
  border-right: none;

}

.password_lable {
  font-size: 1.375rem;;
  font-weight: 600;
  color: black;
  position: absolute;
  top: 6%;
  left: 1%;
  text-align: center;
}

.password_btn {
  color: #fff;
  letter-spacing: 1px;
  font-weight: 500;
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  padding: 0.375rem 0.75rem;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  transition: 0.3s ease-in-out;
  font-size: 0.9375rem;
  cursor: pointer;
  transition: 0.5s;
  background-size: 200% auto;
  border: navajowhite;
  font-size: 0.75rem;
}

.inputpassword::placeholder {
  font-size: 0.75rem;
}

.form-control_password {
  display: grid;
  grid-template-columns: 1fr 135px;
}

.password-heading {
  font-size: 1.25rem;;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 0;
  text-align: center;
  color: black;
}

@media (max-width: 768px) {
  .password-heading {
    font-size: 0.9375rem;
    font-weight: 600;
  }
}

.inputpassword:focus-visible {
  outline: none;
  border: none;
}