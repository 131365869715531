.nav1 {
  z-index: 201;
  gap: 15px;
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: space-around;
}

.pro_room_btn {
  gap: 15px;
  position: fixed;
  top: 10px;
  z-index: 201;
  right: 10px;
  display: flex;
  cursor: pointer;
}

.nav2 {
  position: fixed;
  top: 0;
  top: 10px;
  z-index: 201;
  gap: 15px;
  display: flex;
  left: 10px;
}

.nav1 button {
  background-color: #00000085;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  gap: 8px;
  z-index: 201;
  border: none;
  outline: none;
}

.pro_room_btn button {
  background: #fff;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  box-shadow: 0px 2px 9px #000000a3;
  box-shadow: 0px 2px 9px #000000a3;
}

.nav__icon {
  font-size: 1.375rem;
  color: #fff;
  font-weight: 600;
}

.exp-btn {
  border-radius: 10px !important;
  width: auto !important;
  display: flex;
  padding: 13px;
  font-weight: 800;
  color: black;
  height: 42px !important;
  align-items: center;
  justify-content: center;
  border: none;
}

.exp-btn-share {
  border-radius: 10px !important;
  width: auto !important;
  display: flex;
  padding: 13px;
  font-weight: 800;
  color: #00000085;
  height: 42px !important;
  align-items: center;
  justify-content: center;




  border: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}


/* 11 */
/* .btn-11 {
  border: none;
  background: rgb(251,33,117);
    background: linear-gradient(0deg, rgba(251,33,117,1) 0%, rgba(234,76,137,1) 100%);
    color: #fff;
    overflow: hidden;
}
.btn-11:hover {
    text-decoration: none;
    color: #fff;
}
.btn-11:before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
    animation: shiny-btn1 2s ease-in-out infinite;
}
.btn-11:hover{
  opacity: .7;
}
.btn-11:active{
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}


@-webkit-keyframes shiny-btn1 {
    0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
    80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
    81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
    100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
} */




@keyframes glowing {
  0% {
    background-color: #00000085;
    box-shadow: 0 0 3px #00000085;
  }
  50% {
    background-color: #00000085;
    box-shadow: 0 0 40px #000;
  }
  100% {
    background-color: #00000085;
    box-shadow: 0 0 3px #000;
  }
}

.exp-btn_chat {
  cursor: pointer !important;
  font-size: 1.9375rem;
  background-color: white;
  color: red !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.hamburger-menu {
  display: none;
}

.cb1 {
  display: none;
}

.imgbtn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 201;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.sharenew {
  position: fixed;
  top: 0;
  top: 10px;
  z-index: 201;
  gap: 15px;
  display: flex;
  right: 65px;
}

.cb {
  padding: 8px 30px;
  border-radius: 9px;
  font-size: 15px;
  font-weight: 600;
  transition: 0.5s;
  color: #fffffff5;
  padding: 8px 12px;
  margin: 10px 10px 10px 10px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  position: fixed;
  top: 2px;
  z-index: 201;
  right: 60px;
  display: flex;
  background: #00000085;
}

.cb:hover {
  box-shadow: -1px 5px 10px rgba(0, 0, 0, 0.35);
}

@keyframes mymove {
  0% {
    background-position: 0px 0px;
  }

  100% {
    background-position: 500px 0px;
  }
}

#fag {
  background: black;
}
.create-btn {
  z-index: 201;
  position: absolute;
  top: 2%;
  right: 6%;
  padding: 10px 20px;
  border-radius: 9px;
  font-size: 1rem;
  font-weight: 700;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.create-btn:hover {
  z-index: 201;
  position: absolute;
  border: 1px solid;
  top: 2%;
  right: 6%;
  padding: 10px 20px;
  border-radius: 9px;
  font-size: 1rem;
  font-weight: 700;
  background-image: linear-gradient(90deg, #f65f65, #ff50e8);
  color: #fff;
}

.button_heading {
  font-size: 15px;
  line-height: 1.45;
  color: #ffffffe0;
}

@media (max-width: 768px) {
  .cb {
    animation: Room_Room_mymove__wUbnd__LmOtm 8s linear infinite;
    background: repeating-linear-gradient(
        247grad,
        transparent 25%,
        hsla(0, 100%, 97%, 0.6) 59%,
        transparent 0,
        transparent
      ),
      #f7ebf1;
    border: none;
    border-radius: 9px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(66, 65, 65, 0.48);
    color: #000;
    font-size: 0.8125rem;
    font-weight: 500;
    margin: 10px;
    padding: 6px;
    transition: 0.5s;
    position: fixed;
    top: 2px;
    z-index: 201;
    right: 47px;
    display: flex;
  }

  .cb1 {
    animation: Room_Room_mymove__wUbnd__LmOtm 8s linear infinite;
    background: repeating-linear-gradient(
        247grad,
        transparent 25%,
        hsla(0, 100%, 97%, 0.6) 59%,
        transparent 0,
        transparent
      ),
      #f7ebf1;
    border: none;
    border-radius: 9px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(66, 65, 65, 0.48);
    color: #000;
    font-size: 0.8125rem;
    font-weight: 500;
    margin: 10px;
    padding: 6px;
    z-index: 201;
    position: fixed;
    top: 2px;
    right: 153px;
    display: flex;
  }

  .hamburger-menu_hight {
    font-size: 1.875rem;
    background-color: white;
    color: black !important;
    height: 40px;
  width: 40px !important;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
  .nav__icon {
    font-size: 1.5375rem;
    color: #000;
  }

  .hamburger-menu {
    position: fixed;
    top: 62px;
    display: flex;
    right: 10px;
  }
  .nav2 {
    flex-direction: column;
    position: fixed;
    top: 112px;
    left: auto;
    right: 10px;
  }

  .nav1 {
    display: none;
  }

  .sharenew {
    position: fixed;
    top: 41% !important;
    z-index: 201;
    gap: 15px;
    display: flex;
    right: 10px;
  }

  .menubar {
    position: fixed;
    top: 105px;
    gap: 8px;
    left: 10px;
    display: flex;
    flex-direction: column;
  }

  .menubar button {
    font-size: 1.875rem;
    background-color: white;
    color: red !important;
    height: 40px !important;
    width: 40px !important;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hum_icon {
    font-size: 1.875rem;
    color: #000;
  }

  .button_heading {
    display: none;
  }

  .pro_room_btn button {
    height: 40px;
    width: 40px;
  }
}

#tidio-chat-iframe {
  display: none !important;
}
