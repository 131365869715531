.main_shareing {
  background: #000000ad;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 201;
  top: 0;
  left: 0;
}

.main {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 201;
  width: 508px;
  color: white;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif !important;
  background-color: #fff;
  margin: 16px 32px;
  border-radius: 15px;
}

.btnSection {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 1.125rem;
  font-weight: 600;
  gap: 21px;
  bottom: 4%;
  margin: 22px 0;
}

.btn {
  padding: 5px 30px;
  background-image: linear-gradient(90deg, #f65f65, #ff50e8);
  color: white;
  border-radius: 15px;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3);
}

.closeButton {
  position: absolute;
  top: 15px;
  left: 12px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.05s ease-in;
  border: none;
}

.closeButton svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 80%;
  width: 80%;
  stroke: #111;
  stroke-width: 2.5px;
  transition: all 0.05s ease-in;
}

/* copy */

.RS_clipboard {
  /* background-color: #111; */
  width: 100%;
  height: 15%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.copy_clipboard_btn {
  padding: 3px 8px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #111;
  color: white;
  cursor: pointer;
  font-weight: 10px;
}

.clipboard_input {
  padding: 6px 18px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  width: 300px;
  color: #111;
  border: none;
}

.alertCopyBtn {
  position: fixed;
  bottom: 0;
  right: 7%;
  background-color: white;
  color: black;
  font-weight: 600;
  font-size: 0.9375rem;
  border-radius: 3%;
  padding: 12px;
  animation: moveUp 0.2s ease-in-out forwards;
}

@keyframes moveUp {
  0% {
    bottom: 0;
    opacity: 0;
  }
  75% {
    bottom: 2%;
    opacity: 0.1;
  }
  100% {
    bottom: 5%;
  }
  0% {
    bottom: 0;
  }
}

.mainContainer {
  height: 250px;
  width: 300px;
  border: 1px solid black;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
}

.generated_id {
  color: rgb(0, 0, 0);
  font-size: 1.875rem;
  font-weight: 800;
  margin: 10px;
}
.gen_id {
  font-size: 1.25rem;
  font-weight: 600;
  color: black;
  background-color: yellow;
}

.login {
  padding: 11px 29px;
  max-width: 440px;
}

.removeButton {
  position: absolute;
  left: 79%;
  top: 9%;
  cursor: pointer;
}

.addButton {
  position: absolute;
  left: 86%;
  top: 9%;
  cursor: pointer;
}

.share_icon {
  color: #111;
  font-size: 1.875rem;
}

.share_submit {
  background-image: linear-gradient(
    to right,
    #cb2d3e 0%,
    #ef473a 51%,
    #cb2d3e 100%
  );
  margin: 2px auto;
  padding: 8px 11px;
  text-align: center;

  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  border: none;
}

.share_submit:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.invite_heading {
  color: #111;
  font-size: 1.5625rem;
  font-weight: 800;
  text-align: center;
  margin: 10px;
  top: 10px;
  position: sticky;
  background: white;
}

.main_invite {
  margin: 11px 28px;
}

.sharing_input {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin: 9px 0 !important;
  padding: 11px 7px !important;
  font-size: 0.9375rem;
  border-radius: 7px;
  font-family: "Montserrat", sans-serif;
  border: 1px solid black;
}

@media (max-width: 768px) {
  .main {
    position: absolute;
    top: 46%;
    left: 39%;
    transform: translate(-50%, -50%);
    z-index: 201;
    height: 380px;
    width: 320px;
  }
  .clipboard_input {
  }
  .removeButton {
    left: 79%;
    top: 11%;
  }
  .addButton {
    left: 68%;
    top: 11%;
  }
  .sharing_input::placeholder {
    font-size: 0.8125rem;
  }
}

@media (max-height: 450px) {
  .main {
    height: 245px;
    width: 508px;
  }
  .main_invite {
    margin: 11px 28px;
    overflow: scroll;
    height: 90px;
  }
  .sharing_input {
    font-size: 0.25rem;
  }
  .sharing_input::placeholder {
    font-size: 0.6875rem;
  }
}
