.Wrapper-name {
  font-size: 1.875rem;
  font-weight: 600;
  color: black;
  padding: 10px;
}


.world-details-wrapper-scroll {
  overflow-y: scroll;
  margin-bottom: 20px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  height: 188px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.world-details-wrapper {
  /* overflow-y: scroll; */
  margin-bottom: 20px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  /* height: 197px; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.wrapper-first{
  background: white;
  border-radius: 8px;
  justify-content: space-between;
  color: black;
  position: relative;
}

.meta_create {
  z-index: 201;
  height: 166px;
  width: 220.16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  padding: 17px;
  margin: 4px 0 10px 5px;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 15px;
}

/* .closeBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
  box-shadow: 0 2px 2px black !important;
  transition: all 0.05s ease-in;
  border: none;
}

.closeBtn:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 5px black;
}

.closeBtn:hover svg {
  stroke: red;
}

.closeBtn svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);

  height: 80%;
  width: 80%;
  stroke: #111;
  stroke-width: 2.5px;

  transition: all 0.05s ease-in;
} */

.MM_createBtn {
  background-image: linear-gradient(to right, #ff512f, #dd2476);
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);

  transition: all 0.2s ease-in;
  backface-visibility: none;
  border: none;
}

.MM_createBtn:hover {
  transform: scale(1.02);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  /* padding: 15px 30px; */
}

.MM_noWorld {
  text-align: center;
}

.MM_noWorld p {
  color: black;
  font-weight: 400;
  font-size: 1.375rem;
  padding: 10px;
}

@media (max-width: 791px) {
  .meta_create {

    height: 166px;
    width: 205.16px !important;

  }

  .Wrapper-name {

    padding: 8px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: auto;
  }
}
@media (max-width: 500px) {
  .wrapper {
    width: auto;
  }
  .world-details-wrapper {
    overflow-y: scroll;
    margin-bottom: 20px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    height: 125px;
   
  }

  .meta_create {
    height: 90px;
    width: 234.25px !important;

  }

  .world-details-wrapper-scroll {
    gap: 1px;
   
}

.MM_noWorld p {
  font-size: 1.175rem;
  padding: 1px;
}

.MM_createBtn {
  padding: 5px 12px;
  font-size: 0.85rem;
  border-radius: 8px;
}
}
