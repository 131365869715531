.voiceListContainer {
  position: fixed;
  top: 100px;
  left: 10px;
  z-index: 201;
  width: 120px;
  max-height: 300px;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.218);
  font-size: 12px;
}
/*-----------------all voice-------------------*/
.allVoice {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
}
.allVoice button {
  outline: 0;
  border: 0;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  color: black;
  width: 100%;
  color: white;
}
.mutedBackground {
  background-color: red;
}
.unmutedBackground {
  background-color: green;
}
/*-----------------voice list-------------------*/
.voiceListPerson {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
}
.voiceListPerson button {
  outline: 0;
  border: 0;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  color: black;
  color: white;
}
.voiceListPersonName {
  padding: 5px;
  color: white;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
}
.divider {
  border-bottom: 2px solid rgba(255, 255, 255, 0.475);
  padding-top: 2px;
}
