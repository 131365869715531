/*-----------------loadingScreen-------------------*/
.loadingScreenContainer {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  color: white;
  font-size: 24px;
  z-index: 201;
}

.helpScreen {
  z-index: 201;
  position: absolute;
  top: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  /* border: 2px solid black; */
}

.loadingScreen {
  padding: 20px;
  margin-left: 30px;
  height: max-content;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

.loadingTitle {
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
}

.loadingSubTitle {
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
}

.loadingDescription {
  font-size: 12px;
  font-weight: 800;
  color: #fff;
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
  text-align: left;
}

.loadingCount {
  font-size: 25px;
  font-weight: 800;
  color: #fff;
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
}

.shortcutScreen {
  padding: 20px;
  width: 20%;
  background-image: url(../../../../../public/R3F/image/Wallpaper/help.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: inline-block;
  align-self: stretch;

  /* border: 2px solid red; */
}

@media screen and (max-width: 1000px) and (min-width: 600px) {
  .helpScreen {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 70vh;
    width: 50vw;
    flex-direction: column;
    align-items: center;
  }

  .loadingScreen {
    padding: 0;
    width: auto;
  }
}

@media screen and (max-width: 600px) {
  .helpScreen {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    flex-direction: column;
    align-items: center;
  }

  .loadingScreen {
    width: auto;
  }
}
