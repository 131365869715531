
.d-section h2 {
    font-size: 1.7rem;
}
.d-section b,.d-section strong {
    font-weight: bolder;
}
.d-section p {
    font-size: 1rem;;
    line-height: 30px;
}

.d-section h5 {
    font-size: 1.5rem;
    letter-spacing: 2px;
    font-weight: 400 !important;
    text-transform: uppercase;
}

.d-section {
    padding: 100px 0 50px;
    background-color: white;
}

.d-section h5, .d-section p, .d-section h2,.d-section ul li {
    color: #080808;
}

.d-section h5 {
    margin: 35px 0 25px 0;
}

.title {
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.d-section a:link,
.d-section a:visited {
    color: grey;
}