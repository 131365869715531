.main-1 {
  background: #000000ad;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 201;
  top: 0;
  left: 0;
}

.profile-logo {
  position: fixed;
}
.ProfileNew {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  font-size: 1.375rem;
  color: #000;
}

.imgbtn {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.btn_text_1 {
  display: flex;
  align-items: center;
}

/* main profile */

.profileContainer {
  text-align: center;
  width: 220px;
  position: fixed;
  background-image: linear-gradient(90deg, #f65f65, #ff50e8);
  color: #000;
  border-radius: 5px;
  right: 1.5%;
  padding: 15px;
  top: 68px;
}

.profileContainer img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.15) !important;

  margin-top: 20px;
}

.profileContainer h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: 600;
  margin-top: 12px;
}

.closeBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
  box-shadow: 4px 4px 5px rgb(0, 0, 0, 0.2) !important;
  transition: all 0.05s ease-in;
  border: none;
}

.closeBtn:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 5px black;
}

.closeBtn:hover svg {
  stroke: red;
}

.closeBtn svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);

  height: 80%;
  width: 80%;
  stroke: #111;
  stroke-width: 2.5px;

  transition: all 0.05s ease-in;
}

.nav__icon {
  font-size: 1.125rem;
  color: #000;
  padding: 7px;
}

.button_set {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin: 10px;
}

.navbtn_profile {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100% !important;
  height: 40px !important;
  color: #fff;
  border-radius: 5px !important;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.15) !important;
  border: none;
}

/* 5 */
.btn-5 {
  padding: 0;
  color: black;
  background-color: white;
}

.btn-5:hover {
  color: #f0094a;
  box-shadow: none;
}

.btn-5:before,
.btn-5:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: #f0094a;
  transition: 400ms ease all;
}

.btn-5:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.btn-5:hover:before,
.btn-5:hover:after {
  width: 100%;
  transition: 800ms ease all;
}

.edit_info {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 10px;
}

@media (max-height: 450px) {
  .profileContainer img {
    width: 60px;
    height: 60px;
    margin-bottom: 0px;
  }

  .profileContainer {
    margin-top: 0px;
    padding: 0px;
    position: absolute;
    width: 227px;
  }
  .profileContainer h3 {
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 5px;
  }
  .navbtn_profile {
    font-size: 0.625rem;
    height: 30px !important;
  }
}
@media (max-width: 768px) {
  .imgbtn {
    width: 40px;
    height: 40px;
  }

  .ProfileNew {
    height: 40px;
    width: 40px;
  }
}
