.roomCreationDiv,
.roomCreationDiv2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 32px 15px 32px;
}

.main_creation {
  transform: translate(-50%, -50%);
}

.centerView {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.roomCreationDiv {
  position: relative;
}
.roomCreationDiv2 {
  background-color: yellow;
  flex-wrap: wrap;
}

.radio_img {
  display: inline-block;
  height: 250px;
  width: 250px;
  background-position: center;
}
.meta_box {
  margin-top: 70px;
}

/* .room_creation_input {
    display: inline-block;
    background-color: #d8dcdd;
    box-shadow: 3px 3px 7px rgb(0,0,0,0.32);
    padding: 10px 20px;
    margin: 10px 0;
    width: 320px;
    text-align: center;
    border-radius: 15px;
    border: 1.5px solid transparent;
    transition:all 0.1s ease-in;
}



.room_creation_input:focus {
    transform: scale(1.05);
    border: 1.5px solid #0f0c29 ;
    outline: none !important;
    box-shadow: 5px 5px 10px rgb(0,0,0,0.32);
} */

.room_creation_input-m {
  display: inline-block;
  background-color: #fff;
  box-shadow: 3px 3px 7px rgb(0, 0, 0, 0.32);
  padding: 10px 20px;
  margin: 10px 0;
  width: 320px;
  text-align: center;
  border-radius: 7px;
  border: 1.5px solid transparent;
  transition: all 0.1s ease-in;
}

.room_creation_input-m:focus {
  transform: scale(1.05);
  border: 1.5px solid #0f0c29;
  outline: none !important;
  box-shadow: 5px 5px 10px rgb(0, 0, 0, 0.32);
}

::placeholder {
  color: rgb(117, 120, 123) !important;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 1px;
}

.RC_heading {
  font-size: 2.5rem;
  font-weight: 900;
  color: #382e56;
  text-align: center;
  text-shadow: 3px 3px 6px rgb(0, 0, 0, 0.32);
}
.FR_avtar_image img {
  height: 110px;
  width: 110px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.room_type_heading {
  position: absolute;
  top: 0;
  font-size: 1.125rem;
  font-weight: 600;
}

.step_button {
  background-image: linear-gradient(90deg, #f65f65, #ff50e8);
  border-radius: 9px;
  color: #fff;
  float: right;
  font-size: 1.125rem;
  font-weight: 600;
  height: 45px;
  margin-top: 10px;
  padding: 8px 55px;
}

.sliderWrapper {
  width: 55%;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #000000a1;
  border-radius: 15px;
  padding: 14px 50px;
}
.body_wrapper {
  background: radial-gradient(
      100% 20% at 5% -2%,
      rgba(85, 25, 255, 0.25) 0,
      rgba(85, 25, 255, 0) 100%
    ),
    radial-gradient(
      50% 50% at -20% 100%,
      rgba(154, 207, 255, 0.2) 0,
      rgba(154, 207, 255, 0) 100%
    ),
    radial-gradient(
      50% 50% at 95% 100%,
      rgba(171, 0, 255, 0.2) 0,
      rgba(187, 49, 255, 0) 100%
    ),
    linear-gradient(180deg, #001f42 14.58%, #000e1f 78.96%);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -10;
}

.input_div {
  display: flex;
  justify-content: center;
  column-gap: 50px;
  margin-top: 50px;
}
.adaptive-glass {
  --glass-lightness: 100%;
  background: hsl(0 0% var(--glass-lightness) / 50%);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
}

@media (prefers-color-scheme: dark) {
  .adaptive-glass {
    --glass-lightness: 0%;
  }
}

@supports not (
  (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))
) {
  .adaptive-glass {
    background: hsl(0 0% var(--glass-lightness) / 90%);
  }
}

figure {
  --on-glass-primary: hsl(220 50% 20%);
  --on-glass-secondary: hsl(220 40% 30%);

  border-radius: 0.5ch;
  overflow: hidden;

  /*  https://shadows.brumm.af/  */
  box-shadow: 0 3px 2px hsl(0 0% 0% / 2%), 0 7px 5px hsl(0 0% 0% / 3%),
    0 13px 10px hsl(0 0% 0% / 4%), 0 22px 18px hsl(0 0% 0% / 5%),
    0 42px 33px hsl(0 0% 0% / 6%), 0 100px 80px hsl(0 0% 0% / 7%);
}

@media (prefers-color-scheme: dark) {
  figure {
    --on-glass-primary: hsl(220 50% 90%);
    --on-glass-secondary: hsl(220 30% 75%);

    margin: 0 0 2.5rem;
  }
}

figure img {
  display: block;
  inline-size: 100%;
  block-size: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

figcaption {
  display: grid;
  grid: [stack] 1fr / [stack] 1fr;
  position: relative;
}

figcaption > * {
  grid-area: stack;
}

figcaption > img {
  position: absolute;
  inset: 0;
  /*   reflect hero image for a nice effect!   */
  transform: scaleY(-1);
}

figcaption > section {
  z-index: 1;
  padding-inline: 2ch;
  padding-block: 2ch 1ch;
  color: var(--on-glass-primary);
  display: grid;
  gap: 1ch;
}

@media (prefers-color-scheme: dark) {
  figcaption > section {
    text-shadow: 0 1px 0 hsl(0 0% 0% / 20%);
  }
}

figcaption h3 {
  font-size: clamp(1.25rem, calc(1rem + 2vw), 1rem);
  font-weight: 600;
  text-transform: uppercase;
}

figcaption date {
  color: var(--on-glass-secondary);
}

.RC_heading_1 {
  font-size: 3rem;
  font-weight: 800;
  color: #fff;
  text-align: center;
  text-shadow: 3px 3px 6px rgb(0, 0, 0, 0.32);
}

.body_wrapper {
  padding-top: 5%;
  background-position: center;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -10;
  top: 0;
  background: #fff;
  /* position: fixed; */
  overflow-y: scroll;
}

.metaverse_image {
  margin-bottom: 30px;
  /* border-radius: 15px; */
  overflow: hidden;
  cursor: pointer;
  /* border-radius: 50%; */
  /* box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3); */
  /* border: solid 2px transparent; */
  transition: all 0.2s ease-out;
  /* background: #fff; */
  --on-glass-primary: hsl(220 50% 20%);
  --on-glass-secondary: hsl(220 40% 30%);

  border-radius: 0.5ch;
  overflow: hidden;

  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background: #fff;
  padding: 5px;
}

.metaverse_image:hover {
  box-shadow: 6px 5px 7px rgba(0, 0, 0, 0.3);
  /* border: solid 2px black; */
  border-radius: 7px;
}

.RC_input:checked ~ .metaverse_image {
  box-shadow: 1px 2px 8px rgb(246, 202, 5);
  outline: solid 2px rgb(245, 40, 88);
}

.RC_screeName {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  margin: 16px 0;
  width: auto;
}
.RC_screeName p {
  text-align: center;
}

.meta_image {
  width: 100%;
  height: 100%;
}

/* VALIDATION */

.RC_validation_warning {
  margin: 0;
  padding: 0;
  color: #fff700;
  text-align: center;
  font-size: 1.125rem;
}

@media (max-width: 768px) {
  .input_div {
    margin-top: 15px;
    flex-direction: column;
    align-items: center;
  }

  .RC_heading_1 {
    font-size: 2rem;
    font-weight: 600;
  }
  .metaverse_image {
    margin-bottom: 5px;
    padding: 2px;
  }
  figcaption > section {
    padding-block: 1ch 0ch;
  }
  ::placeholder {
    color: rgb(117, 120, 123) !important;
    font-weight: 600;
    font-size: 0.9375rem;
    letter-spacing: 1px;
  }
}
