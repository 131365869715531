.screenShareContainer iframe {
  border: none;
  background-color: white;
}

.imageScreenContainer {
  background-color: white;
  border: none;
  overflow-y: auto;
  border: 1px solid blue;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoScreenContainer {
  background-color: white;
  border: none;
  overflow-y: auto;
  border: 1px solid blue;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageScreenContainerInside {
  background-color: red;
  word-wrap: break-word;
  cursor: auto;
}
.image-screen {
  background-color: white;
  color: black;
}
