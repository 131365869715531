.pricing-area {
  padding: 100px 0;
  background-image: url("../../assets/images/bg-new.jpg");
  background-position: center center;
  background-size: cover;
  position: absolute;
  width: 100%;
  z-index: -10;
  top: 0;
  min-height: 100%;
  transition: transform 0.3s ease-in-out;
}
.p-btn1 {
  background-color: hsla(198.51428571428573, 96.69%, 64.51%, 1);
  border-color: hsla(198.51428571428573, 96.69%, 64.51%, 1);
}
.p-btn2 {
  background-color: #5263ff;
  border-color: #5263ff;
}
.p-btn3 {
  background-color: #ff50e8;
  border-color: #ff50e8;
}
.p-btn4 {
  background-color: #f65f65;
  border-color: #f65f65;
}

.single-price {
  /* padding-bottom: 15px; */
  background: #fff;
  text-align: center;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  height: 760px;
  color: black;
  margin-bottom: 30px;
}
.single-price button {
  /* background: darkorchid; */
  color: #fff;
  padding: 8px 35px;
  text-decoration: none;
  border-radius: 25px;
  display: inline-block;
  /* position: absolute; */
  justify-content: center;
  display: flex;
  width: max-content;
  bottom: 2%;
  position: absolute;
  /* margin: auto 25%; */
  border: none;
}
.single-price:hover {
  cursor: pointer;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  transform: scale(1.04);
}
.single-price::before {
  content: "";
  width: 100%;
  height: 258px;
  background: #040640;
  position: absolute;
  top: -198px;
  left: 0;
  transform: skewY(341deg);
  background-image: linear-gradient(90deg, #e351cf, #eb7bcf);
}
.single-price .price-header {
  padding: 20px 20px 30px;
  text-align: left;
  position: relative;
}
.single-price .title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: initial;
}
.single-price .price-value {
  display: inline-block;
  width: 170px;
  height: 35px;
  padding: 6px;
  border-top: none;
  border-bottom: none;
  position: relative;
}
.single-price .value {
  width: 103%;
  height: 100%;
  background: #fff;
  /* border: 5px solid #262626; */
  border-top: none;
  border-bottom: none;
  z-index: 1;
  position: relative;
  font-size: 1.5625rem;
}
.single-price .value::before,
.single-price .value::after {
  content: "";
  width: 97px;
  height: 97px;
  background: #fff;
  border: 5px solid #262626;
  border-bottom: none;
  border-right: none;
  position: absolute;
  top: -48px;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%) scaleY(0.5) rotate(45deg);
  display: none;
}
.single-price .value::after {
  border-right: 5px solid #262626;
  border-bottom: 5px solid #262626;
  border-top: none;
  border-left: none;
  top: auto;
  bottom: -48px;
}
.single-price .currency {
  display: inline-block;
  font-size: 1.875rem;
  margin-top: 7px;
  vertical-align: top;
}
.single-price .amount {
  display: inline-block;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 50px;
}
.single-price .amount span {
  display: inline-block;
  font-size: 1.875rem;
  font-weight: normal;
  vertical-align: top;
  margin-top: -7px;
}
.single-price .month {
  display: block;
  font-size: 1rem;
  line-height: 0;
}
.single-price .deals {
  padding: 20px 0 0 0;
  margin-bottom: 10px;
  list-style: none;
  text-align: center;
  transition: all 0.3s ease 0s;
}
.single-price .deals li {
  padding: 6px 0px 0px 40px;
  font-size: 0.9075rem;
  color: #361a1a;
  position: relative;
  text-align: left;
  list-style-type: disc;
  list-style-position: inside;
  font-weight: 500;
}
.talk {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 36px;
}

.pricing_btn {
  position: absolute;
  bottom: 2%;
  left: 25%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 990px) {
  .single-price {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .single-price:before {
    transform: skewY(-15deg);
  }
  .single-price .title {
    font-size: 1.375rem;
  }
}

@media (max-height: 750px) {
  .single-price .deals li {
    font-size: 0.7rem;
  }
  .single-price button {
    padding: 4px 12px;
  }
}

/* @media (max-height: 880px) {
  .single-price {
    height: 550px;


  }

  .single-price .deals li {
    padding: 6px 0px 0px 40px;
    font-size: 0.57rem;
  
  }
  .single-price .deals {
    padding: 3px 0 0 0;
   
  }

  .single-price::before {
    transform: skewY(172deg);
  }
} */
