/*-----------------joystick-------------------*/

.joystick {
  position: absolute;
  bottom: 40px;
  right: 1px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 201;
  border: 5px solid white;
}

.stick {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  position: relative;
  top: 30px;
  left: 30px;
}
