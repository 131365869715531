.main-file {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 1;
}

.main-file img {
  height: 100%;
  width: 100%;
  padding: 5px;
}
.no-exist {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.videoFile {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.upload-image-btn {
  padding: 10px 30px;
}

.container-upload {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 9px;
  gap: 5px;
  background-color: rgba(0, 110, 255, 0.041);
}

.header-upload {
  flex: 1;
  width: 100%;
  border: 2px dashed royalblue;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header-upload svg {
  height: 100px;
}

.header-upload p {
  text-align: center;
  color: black;
}

.footer-upload {
  background-color: rgba(0, 110, 255, 0.075);
  width: 100%;
  height: 40px;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: black;
  border: none;
  position: fixed;
  bottom: 2%;
}

.footer-upload svg {
  height: 130%;
  fill: royalblue;
  background-color: rgba(70, 66, 66, 0.103);
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.205);
}

.footer-upload p {
  flex: 1 1;
  text-align: center;
  margin-bottom: 0;
}

#file {
  display: none;
}

.main-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  border-radius: 10px;
}

.utMain {
  max-width: 100%;
  background-color: white;
  padding: 8px 31px;
  border-radius: 10px;
  margin: 0 auto !important;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.UTinput {
  margin: 0 !important;
  border-radius: 10px;
  padding: 7px 2px 7px 0;
}

.utMain p {
  font-size: 0.75rem;
  color: yellow;
  text-align: center;
  margin: 10px 0 0 0;
}

.UTform {
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* gap: 10px; */
}

.UTbtn {
  padding: 3px 10px;
  border-radius: 10px;
}

.Close-btn-img {
  position: absolute;
  top: 1%;
  right: 1%;
  height: 10px;
}

.svg-wrap1 {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 25px;
  right: 14px;
  cursor: pointer;
}

.svg-wrap {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 11px;
  right: 14px;
  cursor: pointer;
}

.svg-wrap svg {
  height: 100%;
  width: 100%;
  color: red;
}
