.overlay-main {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.758);
  top: 0;
  left: 0;
  z-index: 201;
}

.ins-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 30rem;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.ins-wrapper h3 {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  margin: 15px;
}

.ins-section {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  margin: 30px;
}

.right-logo img {
  height: 60px;
  width: 60px;
  /* border-radius: 50%; */
}

.left-ins-text {
  font-size: 17px;
  font-weight: 400;
}

.closeBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
  box-shadow: 0 2px 2px black !important;
  transition: all 0.05s ease-in;
  border: none;
}

.closeBtn:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 5px black;
}

.closeBtn:hover svg {
  stroke: red;
}

.closeBtn svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 80%;
  width: 80%;
  stroke: #111;
  stroke-width: 2.5px;
  transition: all 0.05s ease-in;
}
