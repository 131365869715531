.lawyer-cta-section .cta-text,
.lawyer-cta-section-s2 .cta-text {
  padding: 55px 0 55px 60px;
}
.lawyer-cta-section h3,
.lawyer-cta-section-s2 h3 {
  font-size: 24px;
  font-size: 1.6rem;
  color: #fff;
  line-height: 1.5em;
  font-weight: 500;
  margin: 0 0 1em;
}
.lawyer-cta-section .lawyer-theme-btn-s2,
.lawyer-cta-section-s2 .lawyer-theme-btn-s2 {
  background-color: #f22648;
  border: none;
}
.lawyer-cta-section .container,
.lawyer-cta-section-s2 .container {
  margin-top: 30px;
  background: url(../../../../assets/images/Bg4.jpg)center center/cover no-repeat local;
}
.lawyer-theme-btn-s2 {
  background-color: #d5aa6d;
  font-size: 16px !important;
  font-size: 1.06667rem;
  font-weight: 600;
  color: #fff !important;
  padding: 9px 30px !important;
  display: inline-block;
}
.lawyer-cta-section h3,
.lawyer-cta-section-s2 h3 {
  font-size: 24px;
  font-size: 2.4rem;
  color: #fff;
  line-height: 1.5em;
  font-weight: 500;
  margin: 0 0 1em;
}

.download-btn {
  color: #f2bc90 !important;
 font-size: 2.4rem !important;
}

.meet-sec {
  text-align: left;
  padding-top: 50px;
}
.about-div {
  margin-top: 20px;
}

.meet-about-content p {
  text-align: justify;
  padding-top: 10px;
  line-height: 28px;
}
.meet-about-img img {
  width: 100%;
  padding: 15px;
  border-radius: 20px;
}
.meet-about-content h2 {
  font-size: 2rem;
  padding-bottom: 0.4em;
  border-bottom: 1px solid #dedede;
  margin: 0 0 0.3em;
  text-transform: uppercase;
  font-weight: 800;
}
.meet-about-content ul {
  text-align: left;
  line-height: 28px;
}
