a {
  text-decoration: none !important;
}
.details__wrapper {
  max-width: 800px;
  margin: 1em auto;
}
a {
  text-decoration: none;
}
.details__wrapper_one {
  max-width: 900px;
  margin: 2em auto;
  background: #ffffff3d;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 3px 10px #01101e, 0 3px 8px rgb(223 217 217 / 23%);
}
.ant-steps-item-title {
  color: #52c9ea !important;
  font-weight: 800 !important;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fff;
  font-weight: 600;
}
.ant-steps-item-icon {
  background-color: rgb(255 254 250 / 64%) !important;
  border-color: transparent !important;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0px 0px;
  padding: 0px 5px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 0;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: "Montserrat", sans-serif;
  display: flex;
  width: 100%;
  text-align: initial;
}
.ant-btn-primary {
  color: #fff;
  background-color: #06d7f3;
  box-shadow: 0 2px 0 rgb(5 145 255 / 10%);
}
.ant-btn {
  font-size: 15px;
  height: 100%;
  padding: 4px 30px;
  border-radius: 6px;
  margin: 10px;
}
.ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #36cd36;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1fcb1f;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 9px 8px;
  color: #fff;
  font-size: 15px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: "Montserrat", sans-serif;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff36;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #625d5d;
  border-radius: 4px;
  transition: all 0.2s;
}
.np-btn {
  float: right;
  margin-top: -35px;
}
.form__item {
  margin-bottom: 1em;
}
::placeholder {
  color: #fff !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff !important;
}
.deatil-div {
  color: #fff;
}
label,
input {
  display: block;
  width: 100%;
}
.d-flex {
  display: flex !important;
}
.screen-div {
  background: #fff;
  padding: 3px;
  box-shadow: 0 10px 6px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  border-radius: 5px;
  min-height: 160px;
}
.screen-div img {
  height: 120px;
}
.lable-radio {
  display: flex;
  padding: 10px;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.image {
  width: -webkit-fill-available;
  height: 160px;
  background-position: center center;
  background-color: gray;
  display: inline-block;
  margin: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.m-t-20 {
  margin-top: 5px;
}
.avatar-image {
  width: -webkit-fill-available;
  height: 150px;
  width: 100%;
  background-position: center center;
  background-color: #fff;
  display: inline-block;
  margin: 0px;
  background-size: cover;
  background-repeat: no-repeat;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #06d7f3;
  border-color: #e0f2f5;
}
.radio-img {
  /* background: #fff; */
  text-align: center;
}
.image:hover {
  opacity: 1;
}
.radio-img > input {
  display: none;
}
.radio-img > .image {
  cursor: pointer;
  /* border: 1px solid #3c4d64; */
  /* border-radius: 10px; */
  box-shadow: 0 1px 5px rgb(0 0 0), 0 3px 2px rgb(0 0 0 / 23%);
  border-radius: 5px;
}
.radio-img > input:checked + .image {
  border: 2px solid orange;
}

.justify-content-between {
  justify-content: space-between !important;
}
.input__error input {
  border-color: red;
}
.error__feedback {
  color: #ddaa1ced;
}
