.home_section_1 {
  /* background-image: url("../../../assets/images/website/bg2.jpg"); */

  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.welcome_container {
  width: 100vw;
  height: 70vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.welcome_container h3 {
  color: white;
}
html,
body {
  /* height: 100%; */
}

.wrap {
  display: flex;
  align-items: center;
}

.mainbutton {
  font-size: 1.125rem;
  background: linear-gradient(
    270deg,
    rgb(0, 163, 255) -36.05%,
    rgb(3, 255, 236) 46.96%,
    rgb(0, 255, 178) 162.7%
  );
  font-weight: 500;
  color: #000;
  padding: 7px 20px;
  border-radius: 5px;
}
.mainbutton2 {
  margin: 10px;
  position: relative;
  display: flex;
  width: auto;

  padding: 10px 50px 10px 25px;
  border: 1px solid #fff;
  border-radius: 100px;
  transition: 0.3s ease-in-out;
  font-size: 1.0625rem;
  font-weight: 600;
  cursor: pointer;
  background-image: linear-gradient(90deg, #f65f65, #ff50e8);
  color: #fff;
  display: table-footer-group;
}
.mainbutton2:hover {
  color: black;
  background: none;
  border: 1px solid black;
  /* -webkit-filter: invert(100%);
  filter: invert(100%); */
}
.mainbutton3 {
  font-size: 1.0625rem;
  background: none;
  font-weight: 400;
  color: #000;
  padding: 8px 24px;
  border-radius: 5px;
  margin-left: 20px;
  margin-top: 15px;
  border: 1px solid black;
  border-radius: 100px;
  color: black;
}
.mainbutton3:hover {
  border-color: #000;
  background-color: #000;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.main-video {
  min-width: 100%;
  min-height: 65vh;
  /* z-index: 1; */
  /* position: absolute; */
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-text {
  color: #fff;
}

.head_title {
  text-align: center;
  left: 39%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .button {
    width: 130px;
    font-size: 0.75rem;
  }
  .head_title h3 {
    font-weight: 500;
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 768px) {
  /* For mobile phones: */
  .button {
    width: 250px;
    font-size: 1.375rem;
  }
  .head_title h3 {
    font-weight: 600;
    font-size: 2.5rem;
  }
}

.button:hover,
.button:focus {
  color: #313133;
  transform: translateY(-6px);
}

button:hover::before,
button:focus::before {
  opacity: 1;
}

button:hover::after,
button:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
    @media;
  }
}

/* button::before {
  content: "";
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  border: 6px solid #00ffcb;
  box-shadow: 0 0 60px rgba(0, 255, 203, 0.64);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
} */

/* button::after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #00ffcb;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
} */
